.Modal {
  padding-bottom: 0;

  &__PrefixText {
    margin-bottom: 2.4rem;
  }

  .ant-modal-content {
    align-items: flex-start;
    border-radius: 0.4rem;
    display: flex;
    flex-direction: column;
    gap: 2.4rem;
    justify-content: flex-start;
    padding: 2.4rem;
    position: relative !important;

    .ant-modal-close {
      color: $white;
      top: 0;
      right: -3rem;
      height: 2.4rem;
      position: absolute;
      width: 2.4rem;

      .ant-modal-close-x {
        height: 100%;
        line-height: normal;
        width: 100%;

        .anticon {
          > svg {
            font-size: 2.4rem;
          }
        }
      }
    }

    .ant-modal-header,
    .ant-modal-body,
    .ant-modal-footer {
      width: 100%;
    }

    .ant-modal-header {
      border-bottom: 0;
      margin-top: -1.2rem;
      padding: 0 !important;

      > .ant-modal-title {
        @include openSansSemiBold;
        color: $black;
        display: block;
        font-size: 2rem;
        line-height: 2.7rem;
      }
    }

    .ant-modal-body {
      @include openSansRegular;
      align-items: flex-start;
      background: $white;
      border-radius: 0.4rem;
      color: $black;
      display: flex;
      flex-direction: column;
      font-size: 1.4rem;
      height: fit-content;
      justify-content: flex-start;
      line-height: 1.9rem;
      padding: 0 !important;

      > * {
        width: 100%;
      }
    }

    .ant-modal-footer {
      align-items: center;
      display: flex;
      border-top: 0;
      gap: 1.6rem;
      justify-content: flex-end;
      padding: 0;
      padding-top: 0;
      text-align: right;

      .ant-btn {
        @include openSansRegular;
        font-size: 1.4rem;
        height: 3.2rem;
        margin: 0;
      }
    }
  }

  &--Fullscreen {
    height: 100vh;
    max-width: 100% !important;
    width: 100vw !important;

    .ant-modal-content {
      border-radius: 0;
      height: 100%;
      padding: 0;
      width: 100%;

      .ant-modal-close {
        color: $dark-gray;
        margin: 0;
        right: 2.4rem;
        top: 2.4rem;
      }

      .ant-modal-body {
        margin-top: -4.8rem;
        height: 100%;
        width: 100%;
      }
    }
  }
}
