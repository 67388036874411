.CheckoutStatusIconPopoverContent {
  @include openSansRegular;
  color: $white;
  display: flex;
  flex-direction: column;
  font-size: 1.2rem;
  line-height: 1.7rem;

  &__Title {
    margin-bottom: 0.8rem;
  }

  &__CheckoutCheckItem {
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%;

    &:not(:last-child) {
      margin-bottom: 0.6rem;
    }
  }
}
