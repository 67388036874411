.TodaysActivityHeader {
  align-items: center;
  display: flex;
  justify-content: space-between;

  &__Title {
    @include openSansSemiBold;
    align-items: center;
    color: $black;
    display: flex;
    font-size: 2rem;
    line-height: 2.7rem;

    &__Text {
      margin-right: 1.4rem;
    }

    .CheckoutStatusIcon {
      color: $red;
    }
  }
}
