.WorkDaySchedule {
  @include workTimeWidget;
  max-width: 25.6rem;

  &__Title {
    > .anticon {
      color: $red;
      font-size: 1.5rem;
    }
  }
}
