.CheckoutUnallocatedTimeBar {
  @include checkoutTimeBarTooltip;
  background-color: #bfbfbf;
  .ant-tooltip-content {
    :before {
      border-right: 2px solid #bfbfbf;
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      height: 50%;
      width: 1px;
      top: 0;
      transform: translateY(50%);
    }
    .CheckoutTimeBarTooltip {
      &__Title {
        color: #bfbfbf;
      }
    }
  }
}
