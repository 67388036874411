.CheckoutPanel {
  &--Page,
  &--LeaveTheOffice,
  &--History {
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%;

    .CheckoutPanel {
      &__Body {
        background-color: #e6e6e6;
        flex: 1;
        overflow: auto;
        padding: 0;
        width: 100%;
      }

      &__Card {
        background-color: #ffffff;
        margin: 2.4rem;
        border-radius: 4px;

        &__Title {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 5rem;

          &__Left {
            &__Date {
              font-size: 2rem;
              line-height: 2.7rem;
              font-weight: 600;
            }
          }

          &__Right {
            align-items: center;
            display: flex;
            justify-content: flex-end;

            &__ModifyBtn {
              margin-left: 1.6rem;
            }
          }
        }
      }

      &__Main {
        padding: 4.8rem;

        > * {
          &:not(:last-child) {
            margin-bottom: 2.4rem;
          }
        }
      }
    }
  }

  &--LeaveTheOffice {
    height: fit-content;
    padding: 2.4rem;

    .CheckoutPanel {
      &__Card {
        margin: 0;
      }

      &__Main {
        padding: 0 0 2.4rem 0;

        > * {
          &:not(:last-child) {
            margin-bottom: 2.4rem;
          }
        }
      }
    }

    .NextWorkDay {
      padding-top: 0;
      padding-bottom: 2.4rem;
    }

    .CheckoutPanel__LeaveTheOffice {
      padding-top: 0;
    }

    .WorkTimes {
      flex-wrap: wrap;
      .CheckoutItem {
        margin-right: 0;

        &:first-child {
          flex-basis: 100%;
          margin-bottom: 2.4rem;
        }
        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4) {
          flex-basis: 30.5743%;
          margin-bottom: 2.4rem;
        }

        &:nth-child(5),
        &:nth-child(6) {
          flex-basis: 47.9729%;
        }
      }
    }
  }

  &--History {
    background: transparent !important;
    box-shadow: none !important;
    height: fit-content;

    .CheckoutHeader {
      border-radius: 0.4rem;
      margin-bottom: 2.4rem;
    }

    .CheckoutPanel {
      &__Card {
        margin: 0;
      }
    }
  }

  &__Loading {
    align-items: center;
    display: flex;
    justify-content: center;
    flex-grow: 1;
    width: 100%;
  }
}
