.CustomDatePicker {
}
.react-datepicker-wrapper {
  .react-datepicker__input-container {
    @include flexCenter();
  }
}

.CustomDatePicker__Popper {
  @include grayBorder();
  background: $white;
  border-radius: 0.4rem;
  box-shadow: 0 0 0.3rem $gray;
  margin-top: -2rem !important;
  margin-left: -16.2rem;
  min-height: 35.2rem;
  padding: $m-size;
  width: 32.4rem;
  z-index: 3;

  > div {
    padding: 0 !important;
  }

  .CustomDatePicker__CustomContainerWrapper {
    .react-datepicker {
      align-items: center;
      border: none;
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: center;
      width: 100%;

      .CustomDatePicker__CustomContainerWrapper__Buttons {
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin-bottom: $m-size;
        width: 100%;

        .CustomDatePicker__CustomContainerWrapper__Buttons__NextShiftDisabled {
          opacity: 0.5;
        }

        > * {
          @include openSansRegular();
          @include grayBorder();
          border-radius: 0.4rem;
          cursor: pointer;
          font-size: 1.2rem;
          min-height: 2.4rem;
          padding: 0.4rem 0;
          text-align: center;
          min-width: 9.2rem;
        }

        > * :not(:last-child) {
          margin-right: 0.8rem;
        }
      }

      .CustomDatePicker__CustomContainerWrapper__Calendar {
        position: relative;
        width: 100%;

        .react-datepicker__navigation {
          &.react-datepicker__navigation--previous {
            background: url("../../assets/icons/left-arrow.svg") no-repeat;
            background-position: center center;
            border: none;
          }
          &.react-datepicker__navigation--next {
            background: url("../../assets/icons/right-arrow.svg") no-repeat;
            background-position: center center;
            border: none;
          }
        }

        .react-datepicker__month-container {
          margin-bottom: $m-size;
          width: 100%;

          .react-datepicker__header {
            background-color: $white;
            border-bottom: 0;

            .react-datepicker__current-month {
              @include openSansRegular();
              font-size: 1.2rem;
              margin-bottom: $m-size;
            }

            .react-datepicker__day-names {
              @include openSansRegular();
              align-items: center;
              display: flex;
              font-size: 1.2rem;
              justify-content: space-between;
            }
          }

          .react-datepicker__month {
            .react-datepicker__week {
              @include openSansRegular();
              align-items: center;
              display: flex;
              font-size: 1.2rem;
              justify-content: space-between;
            }

            .react-datepicker__day--today {
              @include grayBorder();
              padding: 0.5rem;
            }
            .react-datepicker__day--selected {
              background-color: $light-green;
              border: none;
              padding: 0.5rem;
            }
          }

          .react-datepicker__day-name,
          .react-datepicker__day,
          .react-datepicker__time-name {
            align-items: center;
            border-radius: 100%;
            display: flex;
            height: 2.8rem;
            justify-content: center;
            margin: 0;
            outline: none;
            width: 2.8rem;
          }
        }

        .CustomDatePicker__Child {
          @include openSansRegular();
          @include grayBorder();
          align-items: center;
          border-radius: 0.4rem;
          cursor: pointer;
          display: flex;
          font-size: 1.2rem;
          justify-content: center;
          min-height: 2.4rem;
          padding: 0.4rem 0;
          text-align: center;
          min-width: 9.2rem;
        }
      }
    }
  }
}
