.WorkShiftNotesToolbar {
  height: 2.4rem;
  width: 23.2rem;

  &__Item {
    &:not(:last-child) {
      margin-right: 0.2rem;
    }
    cursor: pointer;
    font-size: 2rem;
    color: $dark-gray;

    &--Disabled {
      cursor: not-allowed;
    }
  }
}
