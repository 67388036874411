.ConfirmLeavingTheOffice {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  > span {
    @include openSansRegular;
    font-size: 1.2rem;
    line-height: 1.7rem;
    margin-bottom: 2.4rem;
  }

  &--Confirm {
    align-items: center;
    background-color: $off-white;
    border-radius: 0.4rem;
    display: flex;
    flex-direction: column;
    height: 12.9rem;
    justify-content: center;
    padding: 2.4rem;
  }
}
