.ColorPopover {
  width: 26rem;

  > h1 {
    font-size: 1.6rem;
    font-weight: 600;
    line-height: 1.35;
    color: #000000;
    margin-bottom: 2.4rem;
  }

  &__Saturation {
    height: 14rem;
  }

  &__Hue {
    height: 1.6rem;
  }

  &__Saturation, &__Hue {
    width: 100%;
    position: relative;
    margin-bottom: 2rem;
  }

  &__SaturationPointer {
    background-color: transparent;
    border: 1px solid #fff;
    transform: translate(-50%, -50%);
  }

  &__HuePointer {
    background-color: #fff;
  }

  &__SaturationPointer, &__HuePointer {
    width: 1.6rem;
    height: 1.6rem;
    border-radius: 50%;
  }

  &__Info {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 2rem;
  }

  &__Title {
    margin-left: 3rem;
    color: #8c8c8c;
  }

  &__Hex {
    margin-left: 0.8rem;
    text-transform: uppercase;
    border-bottom: 1px dotted #8c8c8c;
  }

  &__Btns {
    display: flex;
    justify-content: flex-end;

    > :first-child {
      margin-right: 2rem;
    }
  }
}