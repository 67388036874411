.ProjectOverview {
  width: 100%;

  &__Main {
    width: 100%;
    height: calc(100vh - 5.6rem);
    overflow-y: scroll;
    padding: 2.4rem;
    background-color: #fff;
  }

  &__Section {
    margin-bottom: 2.4rem;

    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1.2rem;
    }

    &-title {
      font-size: 1.2rem;
      font-weight: 600;
      line-height: 1.7rem;
      letter-spacing: 0.9px;
      text-transform: uppercase;
      margin-bottom: 1.2rem;
      color: #000;
    }

    &-textArea {
      padding: 1.6rem;
    }

    &-operations {
      display: flex;
    }

    &-operations > .ant-tag {
      background-color: #fff;
      border: 1px solid #00000026;
      border-radius: 0.4rem;
      cursor: pointer;
    }

    &-header > &-title {
      margin-bottom: 0;
    }

    &-content {
      width: 100%;
      border: 1px solid #00000026;
      border-radius: 4px;
      padding: 1.6rem;
      font-size: 1.4rem;
      line-height: 1.5;
      color: #000;
      overflow-wrap: break-word;
      white-space: pre-line;
      cursor: pointer;
    }

    &-empty {
      color: #bfbfbf;
    }
  }

  &__SearchBar {
    width: 40rem !important;
    margin-bottom: 0;

    & > .ant-input {
      font-size: 1.2rem;
      line-height: 1.7rem;
    }

    & > .MembersSearchBar__CloseIcon {
      top: 0.4rem;
    }
  }
}

.ProjectOverview__Menu {
  .ant-dropdown-menu-item {
    @include openSansRegular;
    font-size: 1.2rem;
    color: #000;
  }
}

.ProjectOverview__Table {
  border: 1px solid #00000026;
  border-radius: 4px;

  .ant-table-cell {
    padding: 1rem 1.6rem;
  }

  .ant-table-thead .ant-table-cell {
    font-weight: 600;
    line-height: 1.357;
    background-color: #fff;
  }

  .ant-table-row {
    &:last-child {
      .ant-table-cell {
        border-bottom: none;
      }
    }
  }
}

.TaskListModal {
  .ant-modal-body {
    gap: 2.4rem;
  }
  .ant-form {
    width: 100%;

    > .ant-form-item {
      margin-bottom: 0 !important;
    }
  }
}
