.ProjectCollapsePanel {
  flex-grow: 1;

  &__EmptyActivitiesState {
    @include openSansRegular;
    align-items: center;
    border: 1px solid $gray;
    border-radius: 0.4rem;
    color: $dark-gray;
    display: flex;
    font-size: 1.4rem;
    justify-content: center;
    line-height: 1.9rem;
    padding: 2.4rem;
    width: 100%;
  }

  &__Panel {
    &:not(:last-child) {
      margin-bottom: 1.6rem;
    }
  }
}
