.WorkDayEditHistory {
  @include workTimeWidget;

  .WorkDayEditHistory__Content__Data {
    justify-content: flex-start;
    > span:not(:last-child) {
      margin-right: 1.6rem;
    }
  }
}
