.ProjectCollapsePanelContent {
  background-color: $white;
  border-top-left-radius: 0.4rem;
  border-top-right-radius: 0.4rem;

  &__Panel {
    &:last-child {
      > .ant-collapse-header {
        border-radius: 0 !important;
      }
    }
    > .ant-collapse-content {
      border: 0;
      > .ant-collapse-content-box {
        padding: 0;
      }
    }
    &__Content {
      border: 0;

      &--Loading {
        align-items: center;
        display: flex;
        justify-content: center;
        padding: 1.2rem;
      }

      &__TaskPanel {
        border: 0 !important;
        border-top: 1px solid $gray !important;

        &:last-child {
          border-radius: 0 !important;
        }

        > .ant-collapse-header {
          background-color: $white;
          padding: 1.2rem 1.6rem 1.2rem 4.8rem !important;
        }
        > .ant-collapse-content {
          > .ant-collapse-content-box {
            background-color: $off-white !important;
            padding: 2rem 1.6rem 2rem 4.9rem;
          }
        }
      }
    }
  }
}
