.ShiftsTable {
  margin-bottom: 2.4rem;

  &__Header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.2rem;
  }

  &__Title {
    font-size: 1.2rem;
    font-weight: 600;
    line-height: 1.9rem;
    letter-spacing: 0.9px;
    color: #000;
    text-transform: uppercase;
  }

  &__Extra {
    display: flex;
    align-items: center;

    &-fresh {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 2rem;
      height: 2rem;
      padding: 0.2rem;
      margin-right: 0.6rem;
      border: 1px solid #00000026;
      border-radius: 4px;
      cursor: pointer;
    }

    &-freshIcon {
      color: #0000007f;
      font-size: 1.4rem;

      &-loading {
        animation-name: spin;
        animation-duration: 1000ms;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
      }
    }

    &-add {
      color: #40bf00;
      font-size: 1.6rem;
    }
  }

  &__Table {
    border: 1px solid #00000019;
    border-radius: 4px;

    &-header {
      position: relative;
    }

    &-icon {
      position: absolute;
      color: #0000007f;
      right: -2rem;
      top: 0.4rem;
      z-index: 1;
    }

    .ant-table-tbody .ant-table-cell {
      padding: 1.2rem 1.6rem;
      border-bottom: none;
    }

    .ant-table-thead .ant-table-cell {
      padding: 1rem 1.6rem;
      font-weight: 600;
      font-size: 1.4rem;
      line-height: 1.9rem;
      background-color: #fff;
      border-bottom: 1px solid #00000019;
    }

    .ant-table-row {
      &:last-child {
        .ant-table-cell {
          border-bottom: none;
        }
      }
    }

    .ant-table-thead,
    .ant-table-tbody {
      .ant-table-cell {
        color: #000;

        &::before {
          display: none;
        }

        &:nth-child(1),
        &:nth-child(4) {
          border-right: 1px solid #00000019;
        }
      }
    }
  }

  &__Column-draft {
    color: #0079f2;
    font-size: 1.6rem;
    border: 1px solid #00000026;
    border-radius: 4px;
    cursor: pointer;
  }

  &__Column-shift {
    display: flex;
    justify-content: center;
    align-items: center;

    &-time {
      margin-right: 0.8rem;
    }

    &-manual {
      margin-right: 0.8rem;
    }

    &-comment {
      color: #0079f2;
      font-size: 1.8rem;
    }
  }

  &__Column-dot {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: 0.8rem;
      left: -1rem;
      width: 0.4rem;
      height: 0.4rem;
      border-radius: 50%;
      background-color: #0079f2;
    }
  }

  &__Column-work {
    color: #40bf00;
  }

  &__Column-break {
    color: #0079f2;
  }

  &__Column-active {
    color: #00bfbf;
  }

  &__Column-manual {
    color: #ff8000;
  }

  &__Column-inactive,
  &__Column-processing {
    color: #0000007f;
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}
