.ProjectTimeBarContainer {
  align-items: center;
  background: $gray;
  display: flex;
  flex-direction: row;
  height: 1rem;
  justify-content: center;
  margin-right: 2.4rem;
  width: 100%;
}
