.MembersSearchBar {
  margin-bottom: 1.6rem;
  border-radius: 4px;

  &__Input {
    width: 36rem !important;
    position: relative;
  }

  &__CloseIcon {
    position: absolute;
    right: 0.8rem;
    top: 0.6rem;
    width: 2rem;
    height: 2rem;
    border: 1px solid #00000026;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  & > .ant-input {
    border-radius: 4px;

    &:focus::placeholder {
      color: #000;
    }
  }

  &__Empty {
    padding: 0.8rem;
  }
}

.MembersSearchBar__Popover {
  padding-top: 0 !important;

  .ant-popover-inner-content {
    padding: 0;
  }
}
