.TaskContributorSelector {
  justify-content: flex-end;

  &__Popover {
    .ant-popover-inner-content {
      padding: 0;
    }
  }

  .CustomAvatar {
    cursor: pointer;
    
    > .ant-avatar-icon {
      background-color: $white;
      border: 1px solid $gray;
      color: $dark-gray;

      > .anticon {
        font-size: 1.6rem;
      }
    }
  }
}
