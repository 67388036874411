.WorkspaceProjects {
  background-color: $white;
  box-shadow: 0px 0px 3px $gray;
  border-radius: 0.4rem;
  padding: 2.6rem 2.4rem;

  &__Header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 2.4rem;

    &__Title {
      @include openSansSemiBold;
      font-size: 2rem;
      line-height: 2.7rem;
    }
    &__Actions {
      align-items: center;
      display: flex;
      gap: 1.6rem;
      justify-content: flex-end;
      width: 50%;

      &__Filter {
        align-items: center;
        border-radius: 0.4rem;
        display: flex;
        justify-content: center;
        height: 3.2rem;
        position: relative;
        width: fit-content;

        .ant-radio-button-wrapper {
          @include openSansRegular;
          background-color: $off-white;
          border: 1px solid $gray !important;
          color: $dark-gray;
          font-size: 1.4rem;
          height: 3.2rem;
          line-height: 1.9rem;
          padding: 0.6rem 1.2rem 0.7rem 1.2rem;

          &:hover {
            color: $black;
          }

          &:first-child {
            border-top-left-radius: 0.4rem;
            border-bottom-left-radius: 0.4rem;
          }

          &:last-child {
            border-top-right-radius: 0.4rem;
            border-bottom-right-radius: 0.4rem;
          }

          &-checked {
            background-color: $white;
            color: $blue;
          }
        }

        .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
          content: none;
        }
      }

      &__Search {
        width: 25.6rem;
      }

      &__CreateProjectBtn {
        @include openSansRegular;
        align-items: center;
        background-color: $light-green;
        display: flex;
        color: $white;
        font-size: 1.4rem;
        justify-content: center;
        line-height: 1.9rem;
        padding: 0.8rem 1.6rem 0.8rem 0.8rem;

        > .anticon {
          font-size: 1.6rem;
        }
      }
    }
  }

  &__Content {
    @include openSansRegular;
    font-size: 1.4rem;
    line-height: 1.9rem;

    .ProjectTable {
      margin-top: 2.4rem;
    }
  }
}
