.UnreadActivities {
  background: #FFF;
  position: relative;
  width: 100%;

  &>.ant-collapse.ant-collapse-icon-position-left {
    height: 100%;
  }

  &__panel {
    background-color: #FFF;
    
    &>.ant-collapse-content {
      height: calc(100vh - 6.8rem);
      overflow-y: scroll;
    }
  }

  &__loading {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ant-collapse-content-box {
    padding: 2.5rem 2.4rem;
  }

  .ant-collapse-content {
    background-color: #F7F7F7;
  }

  .ant-collapse>.ant-collapse-item>.ant-collapse-header {
    padding: 0;
    cursor: default;
  }
}
