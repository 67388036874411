.TaskDescriptionInput {
  &__TextArea {
    font-size: 1.4rem;
    letter-spacing: 0;
    line-height: 2.1rem;
    min-height: 2.1rem;
    padding: 0;
    width: 100%;
  }
  &__Actions {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    margin-top: 1.6rem;

    &__CancelBtn {
      margin-right: 0.6rem;
    }

    &__ConfirmBtn {
      min-width: 9.7rem;
    }
  }
}
