.SummaryOfTheDay {
  @include antCollapsePanelOverride;
  &__EmptyState {
    @include openSansRegular;
    align-items: center;
    border: 1px solid $gray;
    border-radius: 0.4rem;
    color: $dark-gray;
    display: flex;
    font-size: 1.4rem;
    justify-content: center;
    line-height: 1.9rem;
    padding: 2.4rem;
    width: 100%;

    > .anticon {
      color: $warning;
      font-size: 1.6rem;
      margin-right: 0.4rem;
    }
  }
  > .ant-collapse {
    border: 0;
    > .ant-collapse-item {
      background-color: $white;
      border: 0;
      > .ant-collapse-header {
        border: 0 !important;
        background-color: $white;
        margin-bottom: 1rem;
        padding: 0;
      }
      > .ant-collapse-content {
        border: 0;
        > .ant-collapse-content-box {
          align-items: center;
          border: 0;
          display: flex;
          flex-grow: 1;
          justify-content: center;
          padding: 0;
        }
      }
    }
  }
}
