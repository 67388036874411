.WorkShiftNotes {
  width: 100%;

  &__EditorWrapper {
    background-color: $white;
    cursor: text;
    display: flex;
    border: 1px solid $gray;
    border-radius: 0.4rem;
    flex-direction: column;
    justify-content: space-between;
    padding: 1.2rem;

    &--Focused {
      .WorkShiftNotesToolbar {
        display: block;
      }
    }

    &__Toolbar {
      display: none;
      height: 2.4rem;
      width: 100%;

      &__Item {
        &:not(:last-child) {
          margin-right: 0.2rem;
        }
        cursor: pointer;
        font-size: 2rem;
        color: $dark-gray;

        &--Disabled {
          cursor: not-allowed;
        }
      }
    }
  }

  .public-DraftEditorPlaceholder-inner,
  .DraftEditor-editorContainer {
    @include openSansRegular;
    font-size: 1.4rem;
    line-height: 1.8rem;
    margin-bottom: 1.4rem;
  }
}
