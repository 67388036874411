.TaskCardTags {
  @include openSansRegular();
  align-items: center;
  display: flex;
  justify-content: left;

  > * {
    align-items: center;
    display: flex;
    justify-content: left;

    > *:last-child {
      font-size: 1rem;
    }
  }

  .anticon {
    color: $dark-gray;
  }

  &__Dot {
    border-radius: 100%;
    height: 0.2rem;
    width: 0.2rem;
    margin: 0 0.6rem;
    background-color: $black;
  }
}
