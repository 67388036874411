.ShiftCard {
  width: 100%;
  padding: 0.9rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background: #ffffff;
  border: 1px solid hsla(0, 0%, 0%, 0.149);
  border-radius: 4px;

  &__Slot {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 1.2rem;
    line-height: 1.7rem;
  }

  &__Duration {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0.2rem;

    &__Icon {
      color: #0000007f;
      margin-left: 0.6rem;
    }
  }

  &__Title {
    color: #00000080;
  }
}
