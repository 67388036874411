.AddTask {
  display: flex;
  flex-basis: 8rem;
  transition: all 0.3s;

  &__Btn {
    @include openSansRegular;
    align-items: center;
    border: 1px solid $gray;
    border-radius: 0.4rem;
    display: flex;
    flex-basis: 8rem;
    font-size: 1.2rem;
    height: 2rem;
    justify-content: flex-start;
    line-height: 1.7rem;
    padding: 0.2rem 0.4rem;

    > .anticon {
      color: $light-green;
      font-size: 1.6rem;
      margin-right: 0.4rem;
    }
  }

  .TaskSearchBar {
    box-sizing: content-box;
    height: 2rem;
    width: 100%;

    &--Searching {
      height: 3.2rem;

      .TaskSearchBar__Header {
        height: 3.2rem;
      }
    }

    &__Header {
      height: 2rem;

      &__Input {
        @include openSansRegular;
        font-size: 1rem;
        height: 1.7rem;
        line-height: 1.7rem;
        padding: 0.1rem 0.8rem 0.2rem 0.8rem;
      }

      .ant-popover {
        top: 3.3rem !important;
      }
    }
  }

  &--Expand {
    flex: 1;
  }

  &--Disabled {
    background: #f5f5f5;
    border-color: #d9d9d9;
    box-shadow: none;
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
    pointer-events: none;
    text-shadow: none;
  }
}
