.SectionHeader {
  align-items: center;
  cursor: default;
  display: flex;
  justify-content: flex-start;
  margin: 2.4rem 0;
  padding: 1.8rem;
  position: relative;

  &:before {
    content: "";
    border-top: 1px solid darken($color: $light-gray, $amount: 15%);
    left: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    z-index: 0;
  }

  &__Title {
    align-items: center;
    background: $white;
    border-radius: 1.2rem;
    box-shadow: 0px 0px 3px #00000026;
    display: flex;
    left: 50%;
    line-height: 2.4rem;
    letter-spacing: 0px;
    height: 2.4rem;
    justify-content: center;
    padding: 0.6rem 0.8rem 0.6rem 1.6rem;
    position: absolute;
    text-align: center;
    transform: translateX(-50%);

    &--TaskPanel {
      padding: 0.4rem 1.2rem;

      .SectionHeader__Title__Text {
        @include openSansSemiBold();
        color: $black;
        font-size: 1.2rem;
        height: 1.7rem;
        line-height: 1.7rem;
        margin-right: 0.8rem;
      }
    }

    &__Text {
      @include openSansSemiBold();
      font-size: 1.4rem;
      overflow: hidden;
      margin-right: 0.6rem;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &__Icon {
      cursor: pointer;
      font-size: 2rem;
      transition: transform 0.3s;
      transform: rotate(-90deg);

      &--Open {
        transform: rotate(0deg);
      }
    }
  }

  &__ArchiveActions {
    height: 2.4rem;
    margin-left: auto;

    > * {
      height: 100%;

      &:not(:last-child) {
        margin-right: 0.8rem;
      }
    }
  }
}
