.MembersTable {
  &__Member {
    display: flex;
    align-items: center;
  }

  &__Member-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 1.2rem;
  }

  &__Member-name {
    font-size: 1.4rem;
    line-height: 1.9rem;
    font-weight: 600;
    margin-bottom: 0.4rem;
  }

  &__Member-role {
    font-size: 1.2rem;
    line-height: 1.7rem;
  }

  .ant-empty {
    .ant-empty-img-simple,
    .ant-empty-description {
      display: none !important;
    }
  }
}
