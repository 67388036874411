.ProjectInitial {
  align-items: center;
  border-radius: 0.2rem;
  display: flex;
  height: 2.4rem;
  justify-content: center;
  padding: 0.2rem 0.8rem 0.3rem 0.8rem;
  width: 2.4rem;

  &__Text {
    @include openSansRegular;
    color: $white;
    font-size: 1.4rem;
    line-height: 1.9rem;
  }
}
