.WorkspaceMembersPopoverContent {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
  justify-content: flex-start;

  &__Member {
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 0.6rem;
    justify-content: flex-start;
  }
}
