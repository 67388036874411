.LabelPicker {
  width: 32rem;

  &__Header {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 1.9rem;
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
    border-bottom: 1px solid #00000026;
    color: #000000;
  }

  &__Back {
    position: absolute;
    left: 1rem;
    cursor: pointer;
    font-size: 2.2rem;
    color: #0000007f;
  }

  &__Close {
    position: absolute;
    right: 1.1rem;
    cursor: pointer;
    font-size: 1.8rem;
    color: #0000007f;
  }

  &__Content {
    padding: 1.6rem;
    display: flex;
    flex-direction: column;
  }

  &__Button {
    margin-top: 1.6rem;
  }
}
