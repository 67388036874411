.StarredTeamMemberItem {
  align-items: center;
  background-color: $off-white;
  border: 1px solid #00000019;
  border-radius: 0.4rem;
  color: $black;
  cursor: pointer;
  display: flex;
  height: 3.2rem;
  justify-content: center;
  opacity: 0.5;
  padding: 0.6rem 0.8rem 0.7rem 0.8rem;

  .CustomAvatar {
    margin-right: 0.8rem;
  }

  &--Selected {
    background-color: $white;
    color: $blue;
    opacity: 1;
  }

  &__Avatar {
    margin-right: 0.8rem;
  }

  &__Name {
    @include openSansRegular;
    font-size: 1.4rem;
    line-height: 1.9rem;
    margin-right: 1.2rem;
  }
}
