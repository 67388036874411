.Notifications {
  align-items: center;
  display: flex;
  height: 4rem;
  justify-content: center;
  position: relative;
  width: 4rem;

  &:hover {
    cursor: pointer;
  }

  &__Popover {
    .ant-popover-inner {
      border-radius: 0.4rem;

      .ant-popover-inner-content {
        border-radius: 0.4rem;
        padding: 0;
      }
    }
  }

  &__UnreadFlag {
    color: $red;
    font-size: 0.8rem;
    position: absolute;
    right: 0.2rem;
    top: 0.2rem;
  }

  &__Icon {
    font-size: 1.95rem;
  }
}
