.CheckoutStatusWarning {
  @include openSansRegular;
  background-color: $white;
  border-radius: 0.4rem;
  font-size: 1.4rem;
  line-height: 1.9rem;
  padding: 1.6rem;
  margin-bottom: 0.8rem;
  display: flex;
  justify-content: space-between;

  &__Title {
    display: flex;
    align-items: center;
    font-weight: 600;
  }

  &__Info {
    margin-right: 1.2rem;
  }

  &__Icon {
    color: $red;
    font-size: 2rem;
    margin-right: 0.8rem;
  }

  &__Date {
    color: $blue;
    cursor: pointer;
  }

  &__Button {
    margin-right: 1.6rem;
  }
}
