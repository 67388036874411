.LabelDisplay {
  color: #0000007f;

  svg {
    font-size: 1.6rem !important;
  }

  &__Popover {
    .ant-popover-content {
      width: 100%;
      .ant-popover-inner-content {
        padding: 0;
      }
    }
  }
}
