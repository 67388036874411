.AvatarGroup {
  display: flex;
  width: fit-content;

  > * {
    box-sizing: border-box;
  }
  > *:not(:first-child) {
    margin-left: -0.6rem;
  }
  > *:not(:last-child) {
    border-right: 0.3rem solid $white;
    box-sizing: content-box;
  }

  &__FallbackText {
    text-transform: uppercase;
    background-color: #ccc;
    color: #fff;
    font-size: 100% !important;
  }

  &__Count {
    background: #f2f2f2;
    color: $black-99;
    font-size: 1rem !important;
    z-index: 0;
  }

  &__clickable {
    cursor: pointer;
  }
}
