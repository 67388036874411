.ObserversTooltip {
  width: 19.2rem;
  max-height: 29.6rem;
  font-size: 1.2rem;
  line-height: 1.7rem;
  color: #fff;
  overflow-y: auto;

  &__Title {
    margin-bottom: 0.8rem;
    white-space: pre-wrap;
  }

  &__Item {
    display: flex;
    margin-bottom: 0.6rem;
    &:last-child {
      margin-bottom: 0;
    }
  }

  &__Name {
    margin-left: 0.6rem;
  }

  &::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  &::-webkit-scrollbar:vertical {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background-color: #000;
  }
}
