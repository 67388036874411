.ActivityCardItem {
  display: flex;

  &--Loading {
    align-items: center;
    display: flex;
    justify-content: center;
  }

  &--Deleted {
    background-color: #f7f7f7 !important;
    margin-bottom: 2rem;
    padding: 0.8rem 1.6rem !important;

    > * {
      opacity: 0.5;
    }
  }

  .CustomAvatar {
    margin-right: 1.5rem;
  }

  &__Card {
    width: 38.4rem;
  }
}

.MyDrafts .ActivityCardItem,
.TeamDrafts .ActivityCardItem,
.SubmittedUnconfirmedActivities .ActivityCardItem {
  margin-bottom: 2rem;
  padding: 1.6rem;
}

.ConfirmedActivities .ActivityCardItem,
.UnreadTaskActivities .ActivityCardItem {
  padding: 0 1.6rem 2rem 1.6rem;
}
