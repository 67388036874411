.CheckoutStatusMessage {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.2rem;
  border: 1px solid #00000026;
  border-bottom: none;

  &:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  &:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-bottom: 1px solid #00000026;
  }

  &__Content {
    display: flex;
    align-items: center;
    font-size: 1.4rem;
    line-height: 1.9rem;
  }

  &__Date {
    flex: 0 0 7.7rem;
    color: #0079f2;
    margin-right: 2.4rem;
    cursor: pointer;
  }

  &__Messages {
    display: flex;
    flex-direction: column;
  }

  &__Message {
    margin-bottom: 0.8rem;
    &:last-child {
      margin: 0;
    }
  }

  &__Button {
    padding: 0.2rem 0.8rem;
    background: #ffffff;
    border: 1px solid #00000026;
    border-radius: 4px;
    font-size: 1.2rem;
    line-height: 1.5rem;
    height: 1.8rem;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
  }
}
