.UserTaskActivityItemGroupedByDate {
  &__Header {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 2rem;
    padding: 1.2rem;
    position: relative;

    &:before {
      content: "";
      border-top: 1px solid darken($color: $light-gray, $amount: 15%);
      left: 0;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 100%;
      z-index: 0;
    }
    &__Title {
      @include openSansSemiBold;
      align-items: center;
      background: $white;
      border-radius: 1.2rem;
      box-shadow: 0px 0px 3px $gray;
      display: flex;
      font-size: 1.2rem;
      height: 2.4rem;
      justify-content: center;
      left: 50%;
      padding: 0.3rem 1.2rem 0.4rem 1.2rem;
      position: absolute;
      text-align: center;
      transform: translateX(-50%);
      width: fit-content;
    }
  }

  &:not(:last-child) {
    .ActivityCardItem {
      margin-bottom: 2rem;
    }
  }

  &__Body {
    .ActivityCardItem {
      &:not(:last-child) {
        margin-bottom: 2rem;
      }

      .CustomAvatar {
        margin-right: 0.8rem;
        .ant-avatar {
          height: 2.4rem;
          width: 2.4rem;
        }
      }
    }

    .ListUsersTaskTimeInWorkDayAndTask,
    &__LastShiftEndDateTime {
      margin-bottom: 2rem;
    }

    &__LastShiftEndDateTime {
      @include openSansRegular;
      font-size: 1.2rem;
      margin-bottom: 1.2rem;
    }

    .ActivityCardItem__Card,
    .ActivityCardItemBody {
      width: 100%;
    }
  }

  &--team {
    .ActivityCardItem {
      margin-left: -3.2rem;
    }
  }
}
