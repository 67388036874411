.RemoveTaskFromWorkDay {
  align-items: center;
  display: flex;
  border: 1px solid $gray;
  border-radius: 0.4rem;
  box-sizing: content-box;
  justify-content: center;

  > .anticon {
    color: $dark-gray;
    font-size: 2rem;
  }
}
