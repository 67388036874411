.SearchTaskCardList {
  background: $white;
  position: relative;
  width: 100%;

  &:first-child {
    .SearchTaskCardItem:last-child {
      border-bottom: 0;
    }
  }

  &:last-child {
    border-bottom-left-radius: 0.4rem;
    border-bottom-right-radius: 0.4rem;

    .SearchTaskCardItem:last-child {
      border-bottom: 0;
      border-radius: 0 0 0.4rem 0.4rem;
    }
  }

  &__Header {
    @include openSansSemiBold();
    align-items: center;
    border: 1px solid $gray;
    display: flex;
    font-size: 1rem;
    height: 2.4rem;
    padding: 0.5rem;
    justify-content: center;
  }

  &__FilteredTasks {
    &__NoMatches {
      @include openSansRegular();
      @include flexCenter();
      border-left: 1px solid $gray;
      border-right: 1px solid $gray;
      color: $dark-gray;
      font-size: $s-size;
      padding-top: $s-size;
      padding-bottom: $m-size;
      text-align: center;
    }
  }
}
