.ProjectTable {
  &__Project {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    &__Title {
      width: 6.2957vw;
    }

    .ProjectInitial {
      margin-right: 1.2rem;
    }
  }

  &__Project__Title,
  &__Description,
  &__AutoActivity {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__Description {
    width: 17.8623vw;
  }

  &__AutoActivity {
    width: 7.2474vw;
  }

  .ant-table {
    border-radius: 0.4rem;
    box-shadow: 0px 0px 3px $gray;

    .ant-table-container {
      .ant-table-content {
      }
    }
  }

  tr > th,
  tr > td {
    background-color: rgba(247, 247, 247, 0);
    white-space: nowrap;

    &:first-child {
      padding-left: 1.6rem;
      width: 21.2121%;
    }
    &:nth-child(2) {
      width: 30.5555%;
    }
    &:nth-child(3) {
      text-align: center;
      width: 14.2424%;
    }
    &:nth-child(4) {
      text-align: center;
      width: 14.5454%;
    }
    &:nth-child(5) {
      width: 10%;
      width: 12.6262%;
    }
    &:nth-child(6) {
      padding-right: 1.6rem;
      text-align: center;
      width: 2.6262%;
    }
  }

  tr > th {
    padding: 1rem 0 1.1rem 0;
  }

  tr > td {
    padding: 0.8rem 0;
  }

  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    content: none;
  }
}
