.TeamPanel {
  background-color: $white;
  flex-grow: 1;
  width: 100%;

  &__Tabs {
    height: 100%;

    > .ant-tabs-nav {
      margin: 0;

      > .ant-tabs-nav-wrap {
        height: 5.6rem;
        padding: 0 1.6rem;

        > .ant-tabs-nav-list {
          > .ant-tabs-tab {
            margin-left: 0;
          }
          > .ant-tabs-tab-active {
            @include openSansRegular;

            > .ant-tabs-tab-btn {
              text-shadow: none;
              font-weight: normal;
            }
          }
        }
      }
    }

    > .ant-tabs-content-holder {
      > .ant-tabs-content {
        height: 100%;
      }
    }
  }
}
