.CheckoutMonthlyWorkDays {
  align-items: center;
  border: 1px solid $gray;
  border-radius: 0.4rem;
  display: flex;
  height: 4rem;
  justify-content: space-between;
  padding: 0.9rem 1.2rem;
  width: 22.8rem;

  > div {
    align-items: center;
    display: flex;

    &.CheckoutMonthlyWorkDays__Header {
      > .anticon svg {
        color: $dark-gray;
      }
      .CheckoutMonthlyWorkDays__Title {
        margin-left: 0.8rem;
      }
    }

    &.CheckoutMonthlyWorkDays__Count {
      @include openSansSemiBold;
      color: $black;
      font-size: 1.6rem;
      line-height: 2.2rem;
    }
  }
}
