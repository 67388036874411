.ContributorItem {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.8rem 1rem;

  &__Info,
  &__Actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__Name {
    margin-left: 0.8rem;
    font-size: 1.4rem;
    line-height: 1.9rem;
  }

  &__Tag {
    margin-left: 0.5rem;
    padding: 0.1rem 0.4rem;
    border-radius: 4px;
    font-size: 1rem;
    line-height: 1.4rem;
    text-align: center;
    color: #fff;
    background-color: #40bf00;
  }

  &__Btn {
    margin-right: 0.8rem;
  }

  &__Close {
    color: #0000007f;
    font-size: 1.6rem;
    border: 1px solid #00000026;
    border-radius: 4px;
    cursor: pointer;
  }
}
