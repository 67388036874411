.TaskAssigneesPopoverContent {
  color: #fff;

  &__Contributor {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 0.6rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__Avatar {
    margin-right: 0.5rem;
  }

  &__Tag {
    margin-left: 0.5rem;
    padding: 0.1rem 0.4rem;
    border-radius: 4px;
    font-size: 1rem;
    line-height: 1.4rem;
    text-align: center;
    color: #fff;
    background-color: #40bf00;
  }
}
