.HistoryPanel {
  align-items: flex-start;
  background-color: $white;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: flex-start;
  width: 100%;

  &__Body {
    flex-grow: 1;
    overflow-y: auto;
    padding: 2.4rem;
    width: 100%;

    > *:not(:last-child) {
      margin-bottom: 2.4rem;
    }

    &__DateRangeDisplay {
      @include openSansSemiBold;
      font-size: 2rem;
      line-height: 2.7rem;
    }

    .SummaryOfTheDayHeader {
      &__Title {
        &__Text {
          margin-right: 0.4rem !important;
        }

        .CheckoutStatusIcon {
          &__Icon {
            font-size: 1.6rem !important;
          }
        }
      }
    }
  }
}
