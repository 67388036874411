.AddToStarred {
  &__Search {
    @include controlIcon();
    padding: 0.3rem 0.8rem 0.4rem 0.4rem;
    width: fit-content;

    > .anticon {
      font-size: 2rem;
      margin-right: 0.4rem;
    }
  }

  &__Icon {
    font-size: 1.6rem !important;

    &--IsActive {
      color: $light-green !important;
    }
  }

  &__Text {
    @include openSansRegular;
    color: $black;
    font-size: 1.2rem;
    line-height: 1.7rem;
  }
}
