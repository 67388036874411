.ActivityInputDisplay {
  position: relative;
  width: 35.1rem;

  &:first-child {
    margin-top: 2rem;
  }

  &:not(:first-child) {
    margin-top: 1.2rem;
  }

  .ActivityInputDisplay__Label {
    @include openSansRegular;
    color: $dark-gray;
    font-size: 1.2rem;
    height: 1.7rem;
    width: 100%;
  }

  .ActivityInputDisplay__Text {
    @include openSansRegular;
    font-size: 1.4rem;
    line-height: 2.1rem;
    margin-top: 0.8rem;
    overflow-wrap: break-word;
    white-space: pre-wrap;
    word-break: break-all;

    p {
      margin-bottom: 0;
    }
  }
}
