.RenameSectionsModal {
  &__Table {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
    height: fit-content;
    justify-content: flex-start;
    width: 100%;

    &__Body {
      width: 100%;

      .ant-form-item {
        margin-bottom: 0;
      }
    }
  }
}
