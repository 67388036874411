.AddWorkspaceMemberModal {
  width: 60.9077vw !important;

  &__Steps,
  &__Steps__Content {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 2.4rem;
    justify-content: flex-start;
    width: 100%;
  }

  &__Steps {
    &__Action {
      align-items: center;
      display: flex;
      gap: 0.8rem;
      justify-content: flex-end;
      margin-left: auto;
    }

    .ant-steps {
      @include openSansSemiBold;
      border: 1px solid $gray;
      border-radius: 0.4rem;
      padding: 1.5rem 3.3rem;
    }

    .ant-steps-item-container {
      align-items: center;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;

      .ant-steps-item-icon {
        align-items: center;
        display: flex;
        justify-content: center;
      }
    }

    .ant-steps-icon,
    .ant-steps-item-title {
      font-size: 1.4rem;
      line-height: 1.9rem;
    }

    .ant-steps-item-title::after {
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &__ProfileForm {
    align-items: flex-start;
    display: flex;
    gap: 2.4rem;
    justify-content: space-between;
    width: 100%;

    .PersonalForm {
      border: 1px solid $gray;
      border-radius: 0.4rem;
      flex-grow: 1;
      padding: 0 1.6rem;
    }
  }

  &__EmailInvitation {
    align-items: center;
    display: flex;
    gap: 0.8rem;
    justify-content: flex-end;
    width: 100%;
  }

  &__Popover {
    .ant-popover-content {
      border-radius: 0.4rem;

      .ant-popover-inner {
        box-shadow: 0px 0px 3px $gray;

        .ant-popover-inner-content {
          padding: 1.2rem;
        }
      }
    }

    &__Content {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      gap: 0.8rem;
      justify-content: flex-start;
      width: 32rem;

      &__Title {
        @include openSansSemiBold;
      }
      &__Message,
      &__Action > .ant-btn {
        @include openSansRegular;
      }
      &__Title,
      &__Message,
      &__Action > .ant-btn {
        font-size: 1.2rem;
        line-height: 1.7rem;
      }

      &__Action {
        align-items: center;
        display: flex;
        gap: 0.8rem;
        justify-content: flex-end;
        width: 100%;

        > .ant-btn {
          align-items: center;
          display: flex;
          height: 2rem;
          justify-content: center;
          padding: 0.4rem 0.8rem;
        }
      }
    }
  }
}
