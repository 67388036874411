.List {
  &__ArchivedNotice {
    align-items: center;
    background-image: linear-gradient(
      125deg,
      #ffedce 25%,
      #fff2d9 25%,
      #fff2d9 50%,
      #ffedce 50%,
      #ffedce 75%,
      #fff2d9 75%,
      #fff2d9 100%
    );
    background-size: 18.31125px 26.1525px;
    border-bottom: 1px solid #fce0bd;
    display: flex;
    height: 4.8rem;
    justify-content: space-between;
    padding: 1.2rem 1.6rem;

    &__Message {
      @include openSansRegular;
      align-items: center;
      display: flex;
      font-size: 1.4rem;
      justify-content: flex-start;
      line-height: 1.9rem;

      > .anticon {
        color: $dark-orange;
        font-size: 2rem;
        margin-right: 1.2rem;
      }
    }
    &__Actions {
      &__BackToNormalBtn {
        @include openSansRegular;
        background-color: $dark-orange;
        border: 0;
        color: $white;
        font-size: 1.2rem;
        height: 2.4rem;
        line-height: 1.7rem;
        padding: 0.3rem 0.8rem 0.4rem 0.8rem;
        width: fit-content;
      }
    }
  }

  > .CardEmptyState {
    margin: 2.4rem 1.6rem;
  }
}
