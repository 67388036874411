.ActivityTabContent {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  overflow-y: scroll;

  > * {
    width: 100%;
  }

  .ActivityTabBody {
    flex-grow: 1;
  }

  .ActivityTabContent__Loader {
    padding-bottom: 2rem;
    text-align: center;
  }
}
