.TeamMemberSearchPopoverContent {
  background-color: $white;
  border: 1px solid $gray;
  border-radius: 0.4rem;
  min-width: 28.8rem;
  position: relative;
  z-index: 1;

  &__Close {
    border: 1px solid $gray;
    border-radius: 0.4rem;
    font-size: 1.6rem;
    padding: 0.4rem;
    position: absolute;
    right: 0.8rem;
    top: 0.3rem;
    z-index: 2;
  }

  &__SelectedMember {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    padding: 0.4rem 0.8rem;

    .CustomAvatar {
      margin-right: 0.8rem;
    }
  }

  &__Search {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 0.5rem;
    padding: 0.4rem 0.8rem;

    &__Input {
      border: none;
      margin-right: 0.8rem;
      padding: 0;
      width: 85%;

      &:hover,
      &:focus {
        border: 0 !important;
        box-shadow: none !important;
      }
    }
  }

  &__MembersList {
    border-top: 1px solid $gray;
    padding-top: 0.8rem;

    &__Item {
      align-items: center;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      padding: 0.8rem;

      &__UserDetails {
        align-items: center;
        display: flex;
        justify-content: flex-start;

        .CustomAvatar {
          margin-right: 0.8rem;
        }
      }

      &__Star {
        border: 1px solid $gray;
        border-radius: 0.4rem;
        font-size: 1.6rem;
        padding: 0.4rem;

        &--Active {
          color: $warning;
        }
      }
    }
  }
}
