.Teammates {
  align-items: center;
  background: white;
  border-left: 1px solid $gray;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  height: 100%;

  &__Divider {
    margin: 0;
    margin-top: -1px;
    border-color: $gray;
  }
}
