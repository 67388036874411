.UnreadTaskPanel {
  display: flex;
  flex-direction: column;

  .ant-divider-inner-text {
    padding: 0;
  }

  &__date {
    padding: 0.4rem 1.2rem;
    background: #FFFFFF;
    box-shadow: 0 0 0.3rem #00000026;
    border-radius: 1.2rem;
    font-size: 1.2rem;
    font-weight: 600;
    line-height: 1.42;
    color: #000000;
    text-align: center;
  }

  &__loading {
    align-self: center;
    margin: 0.6rem auto;
    margin-bottom: 2rem;
    font-size: 2.4rem;
    color: #096dd9;
  }

  &__divider {
    margin-top: 0 !important;
    margin-bottom: 2rem !important;
    border-top-color: rgba(0, 0, 0, 0.06) !important;
  }
}