.TaskHeader {
  margin-left: 4.4rem;
  padding: 1.6rem;
  color: #000;

  &_content {
    margin-left: 4.4rem;
  }
  
  &__title {
    font-size: 1.4rem;
    line-height: 1.9rem;
    margin-right: 0.4rem;
  }

  &__category {
    margin-top: 0.8rem;
    margin-right: 0.4rem;
    font-size: 1rem;
    line-height: 1.4rem;
    cursor: pointer;
  }

  &__category-arrow {
    line-height: 1;
    font-size: 1.2rem;
    cursor: unset;
  }

  &__arrow {
    font-size: 2rem;
    transition: transform 0.3s;
  }

  &__rotate {
    transform: rotate(-90deg);
  }

  &__badge {
    padding: 0.4rem 1.1rem;
    background: #0000000D;
    border-radius: 0.4rem;
    font-size: 1.2rem;
    line-height: 1.7rem;
    color: #00000099;
  }

  &__mark {
    padding: 0.4rem 0.8rem;
    margin-left: 1.6rem;
    background: #FFFFFF;
    border: 1px solid #00000026;
    border-radius: 0.4rem;
    font-size: 1.2rem;
    line-height: 1.7rem;
    color: #000000;
    cursor: pointer;
  }

  &__badge,
  &__mark {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
