.NextWorkDay {
  background-color: $white;
  border-radius: 0.4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 4.2rem;
  width: 100%;

  &__Title {
    @include openSansSemiBold;
    align-items: center;
    display: flex;
    font-size: 2rem;
    justify-content: flex-start;
    line-height: 2.7rem;

    &__Text {
      white-space: nowrap;
    }

    > .anticon {
      font-size: 1.2rem;
    }
  }

  &__Schedule {
    @include workTimeWidget;

    &__Title {
      > .anticon {
        margin-right: 0.6rem;
      }
    }

    &__List {
      align-items: center;
      display: flex;
      flex-flow: wrap;
      justify-content: flex-start;
      gap: 2.4rem;
    }

    &__Content {
      height: fit-content;
      width: 30.5743%;

      &--Empty {
        @include openSansRegular;
        color: $dark-gray;
        font-size: 1.4rem;
        line-height: 1.9rem;
        width: 100%;

        .NextWorkDay__Schedule__Content__Data {
          align-items: center;
          justify-content: center;
        }
      }
    }
  }

  > * {
    &:not(:last-child) {
      margin-bottom: 2.4rem;
    }
    &:first-child {
      margin-bottom: 4.2rem;
    }
  }

  &__Loading {
    align-self: center;
    justify-self: center;
    margin-bottom: 0 !important;
  }
}
