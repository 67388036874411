.NotificationsItem {
  align-items: flex-start;
  column-gap: 1.2rem;
  display: flex;
  height: 8.7rem;
  justify-content: space-between;
  padding: 1.2rem;
  position: relative;
  width: 100%;

  .MarkNotificationAsRead {
    bottom: 1.2rem;
    position: absolute;
    right: 1.2rem;
  }

  &__Display {
    align-items: center;
    display: flex;
    height: 4rem;
    justify-content: center;
    position: relative;
    min-width: 4rem;
    width: 4rem;

    &__UnreadFlag {
      color: $red;
      font-size: 0.8rem;
      position: absolute;
      right: 0.2rem;
      top: 0.2rem;
    }

    &__Icon {
      color: $dark-gray;
      font-size: 1.95rem;
    }
  }

  &__Content {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-between;
    row-gap: 0.8rem;

    &__Text {
      @include openSansRegular;
      color: $black;
      font-size: 1.4rem;
      height: 4rem;
      line-height: 1.9rem;

      &__Highlight {
        color: $blue;
      }
    }

    &__Timestamp {
      @include openSansRegular;
      font-size: 1.2rem;
      line-height: 1.7rem;
    }
  }
}
