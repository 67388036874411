.MoveTask {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 0.8rem;
  font-size: 1.2rem;
  line-height: 1.7rem;
  cursor: pointer;

  &:hover {
    background-color: #f5f5f5;
  }

  &__Icon {
    color: #0000007f;
    margin-right: 1rem;
  }

  &__Popover {
    border-radius: 0.4rem;
    box-shadow: 0 0 3px $gray;
    padding-top: 0;

    .ant-popover-content,
    .ant-popover-inner,
    .ant-popover-inner-content {
      border-radius: 0.4rem;
      margin-top: -4.3rem !important;
      padding: 0;
    }
  }
}
