.DetailsTabContent {
  padding: 2rem 3.2rem 2rem 1.6rem;

  .DetailsTabContent__Section {
    .DetailsTabContent__Section__Title {
      @include openSansSemiBold;
      font-size: 1.2rem;
      letter-spacing: 0.09rem;
      line-height: 1.7rem;
      margin-bottom: 1.2rem;
      text-align: left;
      text-transform: uppercase;
    }
    .DetailsTabContent__Section__Content {
      @include openSansRegular;
      border: 1px solid $gray;
      border-radius: 0.4rem;
      cursor: pointer;
      font-size: 1.4rem;
      letter-spacing: 0;
      line-height: 2.1rem;
      padding: 1.6rem;
      text-align: left;

      .TaskDescriptionInputDisplay {
        overflow-wrap: break-word;
        width: 100%;
        white-space: pre-wrap;
      }
    }
  }
}
