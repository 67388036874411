.StartWork {
  align-items: center;
  background-image: url("../../assets/images/landscape-bg.jpg");
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  width: 100%;

  .StartWork__Carousel {
    align-items: center;
    bottom: 4.7rem;
    display: flex;
    height: 10px;
    position: absolute;
    justify-content: space-between;

    > * {
      border-radius: 100%;
      background-color: $light-gray;
      height: 1.2rem;
      margin-right: 1.2rem;
      width: 1.2rem;

      &:first-child {
        background-color: $white;
      }

      &:last-child {
        margin-right: none;
      }
    }
  }
}
