.LeaveTheOfficeBtn {
  &,
  &:hover,
  &:focus,
  &:active {
    background: $black;
    border: none;
    color: $white;
  }

  @include openSansRegular;
  align-items: center;
  display: flex;
  border-radius: 0.4rem;
  font-size: 1.4rem;
  justify-content: space-between;
  line-height: 1.9rem;
  padding: 1rem;
  position: relative;
  width: 100%;

  &__Text {
    flex-grow: 1;
    margin-left: auto;
  }
}
