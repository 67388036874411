.TaskCollapsePanelHeader {
  align-items: center;
  display: flex;
  justify-content: space-between;

  &__Left {
    &__Title {
      align-items: center;
      color: $black;
      display: flex;
      margin-bottom: 0.8rem;

      &__Text {
        @include openSansRegular;
        font-size: 1.4rem;
        line-height: 1.9rem;
        margin-right: 0.4rem;
      }
      &__Icon {
        cursor: pointer;
        transition: transform 0.3s;
        transform: rotate(-90deg);
        &--Open {
          transform: rotate(0deg);
        }
      }
    }

    &__Tree {
      @include openSansRegular;
      align-items: center;
      display: flex;
      font-size: 1rem;
      line-height: 1.4rem;

      &__Node {
        &:not(:last-child) {
          &::after {
            /* add the arrow */
            background-color: transparent;
            border-right: 1px solid #0000003f;
            border-bottom: 1px solid #0000003f;
            content: "";
            display: inline-block;
            height: 0.4rem;
            margin: 0.1rem 0.6rem;
            transform: rotate(-45deg);
            width: 0.4rem;
          }
        }
      }
    }
  }

  &__Right {
    align-items: center;
    display: flex;
    justify-content: flex-end;

    > *:not(:last-child) {
      margin-right: 1.6rem;
    }

    &__MarkAsReadBtn {
      @include openSansRegular;
      background-color: $white;
      border-radius: 0.4rem;
      border: 1px solid $gray;
      color: $black;
      font-size: 1.2rem;
      height: 2.4rem;
      line-height: 1.7rem;
      padding: 0.3rem 0.8rem 0.4rem 0.8rem;
    }

    &__TotalTaskTimeSeconds {
      @include openSansRegular;
      font-size: 1.4rem;
      line-height: 1.9rem;
    }
  }
}
