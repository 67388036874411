.DeleteConfirmationModal {
  > .ant-modal-content {
    gap: 3.6rem !important;
    padding: 4.8rem !important;

    .ant-modal-body {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
    }
  }

  &__Icon {
    margin-bottom: 3.6rem;
  }

  &__Message {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 1.2rem;

    &__Title {
      @include openSansSemiBold;
    }

    &__Text {
      @include openSansRegular;
    }

    &__Title,
    &__Text {
      color: $black;
      display: block;
      font-size: 1.4rem;
      line-height: 1.9rem;
    }
  }
}
