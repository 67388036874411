.EditHistory {
  &__Header {
    font-size: 1.2rem;
    line-height: 1.7rem;
    font-weight: 600;
    letter-spacing: 0.9px;
    color: #000000;
    text-transform: uppercase;
    margin-bottom: 1.2rem;
  }

  &__Content {
    width: 100%;
    border: 1px solid #00000019;
    border-radius: 3px;
    padding: 1.6rem;
    font-size: 1.4rem;
    line-height: 1.9rem;
  }

  &__Item {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    &:not(:last-child) {
      margin-bottom: 0.8rem;
    }

    &-Date,
    &-Time {
      margin-right: 1.6rem;
    }
  }
}
