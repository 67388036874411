.CheckoutStatusIcon {
  align-items: center;
  display: flex;
  justify-content: center;

  &__Icon {
    font-size: 2rem !important;
  }
}

.CheckoutStatusIcon__Popover {
  min-width: 19.2rem;
  padding-bottom: 0 !important;
  z-index: 1032;

  .ant-popover-content,
  .ant-popover-inner,
  .ant-popover-inner-content {
    background-color: $black;
    border-radius: 0.4rem;
  }

  .ant-popover-inner-content {
    border: 1px solid $light-gray;
    min-width: 10.6rem;
    padding: 0.6rem;
  }

  &--Hidden {
    display: none;
  }
}
