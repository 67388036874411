.CreateNewTaskButton {
  align-items: center;
  border: 1px solid $gray;
  border-radius: 0.4rem;
  cursor: pointer;
  display: flex;
  height: 2.4rem;
  justify-content: center;
  padding: 0 0.8rem 0 0.4rem;
  width: 12.5rem;

  > .anticon {
    color: $light-green;
    font-size: 2rem;
    margin-right: 0.4rem;
  }

  &__Text {
    @include openSansRegular;
    font-size: 1.2rem;
    line-height: 1.7rem;
    white-space: nowrap;
  }
}

.CreateNewTaskButton__Popover {
  border-radius: 0.4rem;
  box-shadow: 0 0 3px $gray;
  padding-top: 0;

  .ant-popover-content,
  .ant-popover-inner,
  .ant-popover-inner-content {
    border-radius: 0.4rem;
    padding: 0;
  }
}
