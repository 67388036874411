.ShiftModal {
  .Modal__PrefixText {
    align-items: center;
    background: $off-white;
    border-radius: 0.4rem;
    display: flex;
    gap: 1.4rem;
    justify-content: flex-start;
    padding: 1.4rem 1.8rem;
    width: 100%;

    > .anticon {
      color: $blue;
      font-size: 2rem;
    }
  }

  &__Content {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
    justify-content: flex-start;
    width: 100%;

    > * {
      width: 100%;
    }
  }

  &__Note {
    width: 100%;
    padding: 1.4rem 1.8rem;
    margin-bottom: 2.4rem;
    background: #f7f7f7;
    border-radius: 4px;
    font-size: 1.4rem;
    line-height: 1.9rem;
    display: flex;
    align-items: center;
  }

  &__Icon {
    flex: 0 0 2rem;
    font-size: 2rem;
    color: #0079f2;
    margin-right: 1.4rem;
  }

  &__Row {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 1.2rem;

    .ant-popover-placement-bottom {
      padding-top: 0.2rem;
    }

    .ant-popover-placement-top {
      padding-bottom: 0.2rem;
    }

    .ant-popover-inner-content {
      width: 16rem;
      padding: 1.2rem;
    }
  }

  &__Column {
    display: flex;
    flex-direction: column;
    flex: 1;

    &:not(:first-child) {
      margin-left: 2.4rem;
    }

    &__Header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1.2rem;
      height: 2rem;
    }

    &__Left {
      display: flex;
      align-items: center;
    }

    &__Title {
      font-size: 1.2rem;
      line-height: 1.7rem;
      font-weight: 600;
      letter-spacing: 0.9px;
      text-transform: uppercase;
    }

    &__Note {
      margin-left: 0.6rem;
      font-size: 1.2rem;
      color: #0000007f;
    }

    &__Refresh {
      padding: 0.2rem;
      color: #0000007f;
      border: 1px solid #00000026;
      border-radius: 4px;
      font-size: 1.4rem;
      cursor: pointer;
    }

    &__Extra {
      &__Btn {
        margin-right: 0.8rem;
      }

      &__Work {
        color: #40bf00;
        font-size: 1rem;
      }

      &__Break {
        color: #0079f2;
        font-size: 1rem;
      }
    }
  }

  &__TextArea {
    margin-top: 2.4rem;
    margin-bottom: 2.4rem;
  }

  &__EditBtn {
    margin-right: 1.6rem;
  }

  &__Draft {
    font-size: 1.5rem;
    color: #0000007f;
  }

  &__Delete {
    font-size: 1.5rem;
    color: #0000007f;
  }
}
