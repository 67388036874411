.UserWorkDaySummaryItemGroupedByDate {
  &__Header {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 2rem;
    padding: 1.2rem;
    position: relative;

    &:before {
      content: "";
      border-top: 1px solid darken($color: $light-gray, $amount: 15%);
      left: 0;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 100%;
      z-index: 0;
    }
    &__Title {
      @include openSansSemiBold;
      align-items: center;
      background: $white;
      border-radius: 1.2rem;
      box-shadow: 0px 0px 3px $gray;
      display: flex;
      font-size: 1.2rem;
      height: 2.4rem;
      justify-content: center;
      left: 50%;
      padding: 0.3rem 1.2rem 0.4rem 1.2rem;
      position: absolute;
      text-align: center;
      transform: translateX(-50%);
      width: fit-content;
    }
  }

  &__WorkDayCheckoutTaskTimeList {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 2rem;

    &__Item {
      align-items: center;
      border: 1px solid $gray;
      border-radius: 1.6rem;
      display: flex;
      height: 2.4rem;
      justify-content: flex-start;
      padding: 0rem 1.2rem;
      width: fit-content;

      &--Team {
        padding-left: 0.3rem;
        padding-right: 1.2rem;
      }

      .CustomAvatar {
        margin-right: 0.6rem;
      }

      &__UserFullName {
        margin-right: 1.2rem;
      }

      &__UserFullName,
      &__Time {
        @include openSansRegular;
        font-size: 1.2rem;
        line-height: 1.7rem;
      }

      &:not(:last-child) {
        margin-right: 0.8rem;
      }
    }
  }

  &__Item {
    &:not(:last-child) {
      margin-bottom: 2rem;
    }

    &__Header {
      align-items: center;
      display: flex;
      justify-content: flex-start;
      margin-bottom: 1.2rem;
      position: relative;

      > * {
        &:not(:last-child) {
          margin-right: 0.8rem;
        }
      }

      .CustomAvatar {
        margin-left: -3.2rem;
      }

      &__UserFullName {
        @include openSansSemiBold;
        font-size: 1.4rem;
        line-height: 1.9rem;
      }

      &__LastShiftEndDateTime {
        @include openSansRegular;
        font-size: 1.2rem;
      }

      &__UnreadTag {
        @include openSansRegular;
        background-color: $red;
        border-radius: 0.4rem;
        color: $white;
        font-size: 1.2rem;
        padding: 0 0.8rem 0.1rem 0.8rem;
      }
    }

    &__Body {
      &__WorkDaySummaryCard {
        background-color: $white;
        border: 1px solid $gray;
        border-radius: 0.4rem;
        padding: 1.6rem;

        &__Title {
          @include openSansSemiBold;
          font-size: 1.4rem;
          margin-bottom: 2.1rem;
          margin-left: -1.6rem;
          padding-left: 1.6rem;

          &--Failed {
            border-left: 3px solid $red;
          }
          &--Passed {
            border-left: 3px solid $light-green;
          }
        }

        &__Content {
          &__Item {
            &:not(:last-child) {
              margin-bottom: 1.2rem !important;
            }
          }
        }
      }
    }
  }
}
