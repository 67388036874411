.WorkspaceView {
  align-items: flex-start;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  height: 100vh;
  overflow: auto;
  position: relative;
  width: 100%;

  .ant-layout-has-sider {
    gap: 2.4rem;
    padding: 2.4rem;
  }

  .ant-layout-has-sider,
  .ant-layout-sider,
  .ant-layout-content {
    background-color: $off-white;
  }

  > .ant-layout-header,
  > .ant-layout {
    width: 100%;
  }

  > .ant-layout-header {
    height: fit-content;
  }

  > .ant-layout {
    flex-grow: 1;
  }
}

.BackToWorkspaceBtn {
  @include openSansRegular;
  background-color: $white;
  border: 0;
  box-shadow: none;
  font-size: 1.4rem;
  line-height: 1.9rem;

  > .anticon {
    font-size: 2.4rem;
  }
}
