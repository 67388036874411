.ProjectTimeBar {
  border-right: 2px solid $white;
  float: left;
  height: 100%;
  min-height: 1rem;
  min-width: 2%;
  transition: all ease 0.3s;
}

.ProjectTimeBar__Tooltip {
  .ant-tooltip-content {
    position: relative;
    .ant-tooltip-arrow {
      display: block;
      position: absolute;
      right: -0.6rem;
      bottom: -1rem;

      .ant-tooltip-arrow-content {
        background-color: transparent !important;
        border-top: 1.5rem solid transparent !important;
        border-left: 1.4rem solid $black;
      }
    }
    .ant-tooltip-inner {
      border-bottom-right-radius: 0;
      margin-bottom: -0.2rem;
    }
  }
}
