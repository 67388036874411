.TaskCardActions {
  @include flexCenter;

  .anticon {
    font-size: 2rem !important;
  }

  > * {
    margin-right: 0.6rem;
  }
}
