.ColorPicker {
  &__Popover {
    .ant-popover-inner-content {
      padding: 0;
    }
  }

  &__Picker {
    flex: 0 0 3.2rem;
    width: 3.2rem;
    height: 3.2rem;
    border-radius: 4px;
    cursor: pointer;
  }

  &__Empty {
    color: #0079f2;
    cursor: pointer;
  }
}
