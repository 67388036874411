.Section {
  > .ant-collapse {
    > .ant-collapse-item {
      > .ant-collapse-header {
        padding: 0 !important;
      }

      > .ant-collapse-content {
        > .ant-collapse-content-box {
          padding: 0 !important;
        }
      }
    }
  }

  &__Header {
    align-items: center;
    cursor: default;
    display: flex;
    justify-content: flex-start;
    margin: 2.4rem 0;
    padding: 1.8rem;
    position: relative;

    &:before {
      content: "";
      border-top: 1px solid darken($color: $light-gray, $amount: 15%);
      left: 0;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 100%;
      z-index: 0;
    }

    .Section__Title {
      align-items: center;
      background: $white;
      border-radius: 1.2rem;
      box-shadow: 0px 0px 3px #00000026;
      display: flex;
      justify-content: center;
      left: 50%;
      line-height: 2.4rem;
      letter-spacing: 0px;
      height: 2.4rem;
      padding: 0.3rem 0.6rem 0.4rem 1.2rem;
      position: absolute;
      text-align: center;
      transform: translateX(-50%);

      &.Section__Title--TaskPanel {
        padding: 0.4rem 1.2rem;

        .Section__Title__Text {
          @include openSansSemiBold();
          color: $black;
          font-size: 1.2rem;
          height: 1.7rem;
          line-height: 1.7rem;
          margin-right: 0.8rem;
        }
      }

      &.Section__Title--ProjectPanel {
        height: fit-content;
        padding: 0.6rem 0.8rem 0.6rem 1.6rem;

        .Section__Title__Text {
          font-size: 1.4rem;
        }
      }

      .Section__Title__Text {
        @include openSansSemiBold();
        margin-right: 0.6rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .Section__Title__Icon {
        cursor: pointer;
        font-size: 2rem;
        transition: transform 0.3s;
        transform: rotate(-90deg);

        &--Open {
          transform: rotate(0deg);
        }
      }
    }
  }

  &__Loader {
    margin-bottom: 1.6rem;
  }

  &__Tasks {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 1.6rem;
    padding-bottom: 2.4rem;
    position: relative;

    > * {
      width: 100%;
    }

    .DraggableItem {
      &:first-child {
        > .TaskCardItem {
          border-top-left-radius: 0.4rem;
          border-top-right-radius: 0.4rem;
        }
      }

      &:not(:last-child) {
        > .TaskCardItem {
          border-bottom: 0;
        }
      }

      &:last-child {
        > .TaskCardItem {
          border-bottom-left-radius: 0.4rem;
          border-bottom-right-radius: 0.4rem;
        }
      }
    }
  }

  &--DraggingOver {
    // style when dragging item over droppable
  }

  &__DroppablePlaceholder {
    background-color: $gray;
    position: absolute;
  }
}
