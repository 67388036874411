.ActivityTabBody {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  &__Loading {
    align-items: center;
    display: flex;
    justify-content: center;
    height: 100%;
  }

  > * {
    width: 100%;
  }
}
