.SummaryOfTheDayHeader {
  align-items: center;
  display: flex;
  height: 2.2rem;
  justify-content: space-between;

  &__Title {
    align-items: center;
    display: flex;
    justify-content: center;

    &__Text {
      @include openSansSemiBold;
      font-size: 1.2rem;
      letter-spacing: 0.09rem;
      line-height: 1.7rem;
      margin-right: 0.8rem;
      text-transform: uppercase;
    }

    .CheckoutStatusIcon {
      color: $red;
    }
  }

  &__Actions {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    width: 68%;

    > * {
      &:not(:last-child) {
        margin-right: 0.6rem;
      }
    }

    &__Filter {
      align-items: center;
      display: flex;
      justify-content: flex-start;

      &__Item {
        @include openSansRegular;
        background-color: $off-white;
        border: 1px solid $gray;
        color: $dark-gray;
        cursor: pointer;
        font-size: 1.2rem;
        height: 2rem;
        line-height: 1.7rem;
        padding: 0.1rem 0.8rem 0.2rem 0.8rem;
        pointer-events: auto;
        text-transform: capitalize;

        &--Active {
          background-color: $white;
          color: $blue;
        }

        &:first-child {
          border-bottom-left-radius: 0.4rem;
          border-top-left-radius: 0.4rem;
        }

        &:last-child {
          border-bottom-right-radius: 0.4rem;
          border-top-right-radius: 0.4rem;
        }
      }
    }

    &__Collapse {
      align-items: center;
      display: flex;
      height: 2rem;
      justify-content: space-between;

      > * {
        background-color: $off-white;

        &:first-child {
          border-bottom-left-radius: 0.4rem;
          border-top-left-radius: 0.4rem;
        }

        &:last-child {
          border-bottom-right-radius: 0.4rem;
          border-top-right-radius: 0.4rem;
        }
      }

      &--NextWorkday {
        > * {
          border-radius: 0.4rem;

          &:not(:last-child) {
            margin-right: 0.6rem;
          }
        }
      }

      .anticon {
        border: 1px solid $gray;
        color: $dark-gray;
        font-size: 1.6rem;
        padding: 0.1rem;
      }
    }
  }
}
