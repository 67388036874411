.WorkspaceMemberAccountPanel {
  background-color: $white;
  box-shadow: 0px 0px 3px $gray;
  border-radius: 0.4rem;
  padding: 2.6rem 2.4rem;

  &__Header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 2.4rem;

    &__Title {
      @include openSansSemiBold;
      align-items: center;
      display: flex;
      flex-direction: row;
      font-size: 2rem;
      gap: 1.2rem;
      justify-content: flex-start;
      line-height: 2.7rem;
    }

    &__Actions {
      align-items: center;
      display: flex;
      gap: 1.6rem;
      justify-content: flex-end;
      width: fit-content;

      .AntButton {
        @include openSansRegular;
        font-size: 1.4rem;
        line-height: 1.9rem;
        padding: 0.6rem 1.2rem 0.6rem 0.8rem;
      }

      &__SendInvite {
        background-color: $light-green;
        border: 1px solid transparent;
        color: $white;
      }
    }
  }

  &__Content {
    &__Section {
      &:not(:last-child) {
        margin-bottom: 2.4rem;
      }

      &__Title {
        @include openSansSemiBold;
        align-items: center;
        display: flex;
        font-size: 1.2rem;
        justify-content: space-between;
        letter-spacing: 0.09rem;
        line-height: 1.7rem;
        margin-bottom: 1.2rem;
        text-transform: uppercase;

        .AntButton {
          @include openSansRegular;
          font-size: 1.2rem;
          height: fit-content;
          line-height: 1.7rem;
          padding: 0.1rem 0.8rem 0.2rem 0.8rem;
        }
      }

      .PersonalForm {
        &__Item {
          justify-content: flex-start;
        }

        .PersonalForm__Item-name {
          flex-basis: 34.7474% !important;
        }

        .PersonalForm__Item-value {
          flex-basis: 36.3636% !important;
          flex-grow: 0 !important;
          margin-bottom: 0;
        }
      }

      .WorkspaceMemberAccountForm,
      .AccessRightsForm,
      .ExternalIntegrationForm {
        .ant-form-item-label {
          flex-basis: 34.7474% !important;
        }

        .ant-form-item-control {
          flex-basis: 36.3636% !important;
          flex-grow: 0 !important;
        }
      }
    }
  }
}
