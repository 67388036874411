.CheckoutHistoryHeader {
  align-items: center;
  display: flex;
  justify-content: flex-start;

  > *:not(:last-child) {
    margin-right: 0.8rem;
  }

  > .anticon {
    color: $black;
    font-size: 2.4rem;
  }

  &__Title {
    @include openSansSemiBold;
    align-items: center;
    display: flex;

    > *:not(:last-child) {
      margin-right: 0.4rem;
    }

    > .anticon {
      font-size: 2rem;
      transition: transform 0.3s;
      transform: rotate(-90deg);
    }
  }

  &--Open {
    .CheckoutHistoryHeader__Title {
      > .anticon {
        transform: rotate(0deg);
      }
    }
  }
}
