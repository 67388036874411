.MoreActions {
  @include controlIcon();
  position: relative;
}

.MoreActions__Popover {
  background: $white;
  border-radius: 0.4rem;
  padding: 0;

  .ant-popover-content {
    width: 100%;
    .ant-popover-inner-content {
      padding: 0;
    }
  }

  &__Content {
    padding: 0.8rem 0;
  }

  &__Action {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding: 0.8rem;
    font-size: 1.2rem;
    line-height: 1.7rem;
    cursor: pointer;

    &:hover {
      background-color: #f5f5f5;
    }
  }

  &__Icon {
    color: #0000007f;
    margin-right: 1rem;
  }
}
