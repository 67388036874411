.VerticalMenu {
  @include openSansRegularSM();
  @include hideScrollBar;
  background: $black;
  color: $menuTextColor;
  height: 100%;
  overflow-y: auto;
  width: 25.6rem;

  .VerticalMenu__menu {
    background: $black;
    height: fit-content;
    padding: $m-size 0;

    .VerticalMenu__menu__menu-item {
      align-items: center;
      display: flex;
      margin: 0;
      padding: 1rem !important;
      position: relative;
      justify-content: left;
      width: 100%;

      > .anticon {
        font-size: 2rem;
      }

      &.VerticalMenu__menu__menu-item--header {
        text-transform: uppercase;
      }

      &.ant-menu-item-selected {
        background-color: $menuItemSelectedColor;
        color: $white;

        &::before {
          display: inline-block;
          content: "";
          height: 100%;
          width: 0.2rem;
          background-color: $light-green;
          position: absolute;
          left: 0;
        }

        .VerticalMenu__menu__menu-item__righthand-badge {
          > sup {
            opacity: 1;
          }
        }
      }

      &.read-only {
        cursor: none;
        pointer-events: none;
      }

      &.ant-menu-item-disabled {
        pointer-events: none;
      }

      &.VerticalMenu__menu__menu-item--submenu {
        padding-left: 4rem !important;
        position: relative;
      }

      .VerticalMenu__menu__menu-item__tracking-badge {
        background: $blue;
        border-radius: 10pt;
        bottom: 0;
        display: inline-block;
        margin: auto;
        font-size: 9px;
        padding: 0 1.1rem;
        position: absolute;
        right: 5rem;
        top: 0;

        &.VerticalMenu__menu__menu-item__tracking-badge--tracking {
          background: $red;
        }
      }

      .VerticalMenu__menu__menu-item__righthand-icon {
        @extend .vertical-center;
        right: 1.2rem;
      }
      .VerticalMenu__menu__menu-item__righthand-badge {
        @include badgeCount;
      }

      .TrackingTag {
        bottom: 0;
        position: absolute;
        right: 5.3rem;
        top: 0;
      }

      &--loading {
        > .ant-menu-title-content {
          align-items: center;
          display: flex;
          justify-content: center;
        }
      }
    }

    .VerticalMenu__menu__menu-header {
      @extend .VerticalMenu__menu__menu-item;
      align-items: center;
      display: flex;
      justify-content: space-between;

      > .ant-menu-title-content {
        align-items: center;
        display: flex;
        justify-content: space-between;
      }

      &__text {
        color: #ffffff7f;
        cursor: default;
        opacity: 0.7;
        font-size: 1.2rem;
        letter-spacing: 0.09rem;
        line-height: 1.7rem;
        text-transform: uppercase;
      }

      &__icons {
        > .anticon {
          border-radius: 0.4rem;
          cursor: pointer;
          color: #ffffff7f;
          font-size: 2rem;
          &:hover {
            background: $dark-gray;
            color: $white;
          }
        }
        > .ant-menu-item-group-list {
          > .ant-menu-item {
            align-items: center;
            display: flex;
            border: 2px solid transparent;
            justify-content: flex-start;
            margin: 0;
            padding-left: 4.2rem !important;
            padding-right: 1rem !important;

            &.ant-menu-item-selected {
              background-color: $subMenuItemSelectedColor;
            }

            .VerticalMenu__SubMenu__ListTitle {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              width: 80%;
            }
          }
        }
      }
    }

    &--Selected {
      background-color: $subMenuItemSelectedColor !important;
      &__ActiveProjectBorder {
        height: 100%;
        left: 0;
        position: absolute;
        width: 2px;
      }
    }
  }

  .VerticalMenu__SubMenu {
    border-left: 2px solid transparent;

    &.ant-menu-submenu-open {
      > .ant-menu-submenu-title {
        > .anticon {
          transform: rotate(0deg);
        }
      }
    }

    &.ant-menu-submenu-selected {
      background-color: $menuItemSelectedColor;
      > .ant-menu-sub {
        background-color: $menuItemSelectedColor;
      }
    }

    > .ant-menu-submenu-title {
      align-items: center;
      display: flex;
      justify-content: flex-start;
      margin: 0;
      padding: 1rem !important;

      > .anticon {
        font-size: 2rem;
        margin-left: auto;
        margin-right: 0;
        transition: transform 0.3s;
        transform: rotate(-90deg);
      }

      .ProjectInitial {
        margin-right: 0.8rem;
      }
    }

    > .ant-menu-sub {
      background-color: $black;

      > .ant-menu-item-group {
        > .ant-menu-item-group-title {
          display: none;
        }
        > .ant-menu-item-group-list {
          > .ant-menu-item {
            align-items: center;
            display: flex;
            border: 2px solid transparent;
            justify-content: flex-start;
            margin: 0;
            padding-left: 4.2rem !important;
            padding-right: 1rem !important;

            &.ant-menu-item-selected {
              background-color: $subMenuItemSelectedColor;
            }

            > .ant-menu-title-content {
              align-items: center;
              display: flex;
              justify-content: flex-start;
            }

            .VerticalMenu__SubMenu__ListTitle {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              width: 80%;
            }
          }
        }
      }
    }

    &--Selected {
      background-color: $subMenuItemSelectedColor !important;
      &__ActiveProjectBorder {
        height: 100%;
        left: 0;
        position: absolute;
        width: 2px;
      }
    }
  }

  &__BetaIndicator {
    align-items: center;
    background: #262626;
    bottom: 0;
    color: $white;
    display: flex;
    gap: 0.8rem;
    height: 4.8rem;
    justify-content: center;
    line-height: normal;
    text-align: center;
    width: inherit;
  }
}
