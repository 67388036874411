.TaskOwner__Popover {
  &.ant-popover {
    background-color: $black;
    border-radius: 0.4rem;
    padding-bottom: 0;
    text-align: center;
    white-space: nowrap;

    &.ant-popover-placement-bottom {
      padding-top: 0;
    }

    .ant-popover-content {
      background-color: $black;
      border-radius: 0.4rem;

      .ant-popover-inner-content {
        background-color: $black;
        border-radius: 0.4rem;
        padding: 0.5rem;

        > div {
          @include openSansRegularSM();
          background-color: $black;
          color: $white;
          font-size: 1rem;
        }
      }
    }
  }

  .ant-popover-inner-content {
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: row;

    .TaskOwner__Popover__OwnerAvatar {
      align-self: flex-start;
      margin-right: 0.9rem;
    }

    .TaskOwner__Popover__Details {
      align-items: center;
      display: flex;
      justify-content: center;
      flex-direction: column;

      > *:first-child {
        color: $white;
      }
      > *:nth-child(2) {
        color: $white-99;
      }
    }
  }
}
