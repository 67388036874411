.TeamHistoryMemberItem {
  &:not(:last-child) {
    margin-bottom: 4.8rem;
  }

  &__Header {
    align-items: center;
    cursor: default;
    display: flex;
    justify-content: flex-start;
    margin: 2.4rem 0;
    padding: 1.2rem;
    position: relative;

    &:before {
      content: "";
      border-top: 1px solid darken($color: $light-gray, $amount: 15%);
      left: 0;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 100%;
      z-index: 0;
    }

    &__Title {
      align-items: center;
      background: $white;
      border-radius: 1.6rem;
      box-shadow: 0px 0px 3px #00000026;
      display: flex;
      justify-content: flex-start;
      left: 50%;
      line-height: 2.4rem;
      letter-spacing: 0px;
      height: 3.2rem;
      padding: 0.4rem 1.6rem 0.4rem 0.4rem;
      position: absolute;
      text-align: center;
      transform: translateX(-50%);

      .CustomAvatar {
        margin-right: 0.8rem;
      }

      &__Text {
        @include openSansSemiBold();
        font-size: 1.2rem;
        line-height: 1.7rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }

  &__Body {
    &__NoWorkDay {
      @include openSansRegular;
      align-items: center;
      background-color: $white;
      border: 1px solid $gray;
      border-radius: 0.4rem;
      display: flex;
      font-size: 1.4rem;
      height: 7.2rem;
      justify-content: center;
      line-height: 1.9rem;

      &:last-child {
        margin-bottom: 2.4rem;
      }
    }

    .SummaryOfTheDay {
      margin-top: 2.4rem;
    }
  }
}
