.AccountForm {
  &__Item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #f0f0f0;
    padding: 1.2rem 0;
    height: 5.6rem;

    &:last-child {
      border-bottom: none;
    }

    > .ant-form-item {
      margin-bottom: 0;
    }

    input {
      width: 44rem;
      border-radius: 4px;
    }
  }

  &__Item-name {
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 1.36;
    margin-bottom: 0;
    flex: 0 0 10rem;
  }

  &__Item-input {
    width: 44rem;
    display: flex;
    justify-content: space-between;
  }

  &__Item-value {
    font-size: 1.4rem;
    line-height: 1.9rem;
    color: #000;
  }

  &__Item-textButton {
    font-size: 1.4rem;
    line-height: 1.9rem;
    color: #0079f2;
    cursor: pointer;
  }

  &__Item-buttons {
    display: flex;
    align-items: center;
  }

  &__Item-button {
    border-radius: 4px;

    &:first-child {
      margin-right: 1.6rem;
    }
  }
}
