.TeamMemberSelectorMoreActionsPopoverContent {
  &__Action {
    @include openSansRegular;
    align-items: center;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    font-size: 1.4rem;
    justify-content: left;
    line-height: 1.9rem;
    padding: 0.6rem 1.2rem 0.7rem 1.2rem;
    width: 100%;
  }
}
