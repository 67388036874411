.ActivityActions {
  @include openSansRegular;
  align-items: right;
  display: flex;
  justify-content: center;
  font-size: 1.2rem;

  > div:not(:last-child) {
    margin-right: 0.6rem;
  }
}
