.TimelineTooltip {
  display: flex;
  align-items: flex-start;
  min-width: 16rem;
  padding: 1.2rem;
  padding-left: 0;
  font-size: 1.4rem;
  line-height: 1.9rem;
  color: #fff;
  background-color: #000;

  &__Popover {
    width: 16rem;
    padding: 1.2rem;
    color: #000;
    background-color: #fff;
  }

  &__Badge {
    width: 0.3rem;
    height: 4rem;
    background-color: #0079f2;
    margin-right: 1.2rem;
  }

  &__Info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__Title {
    font-weight: 600;
    text-transform: capitalize;
  }

  &__Note {
    margin-top: 1.2rem;
    width: 13.3rem;
    white-space: pre-wrap;
  }

  &__Extra {
    display: flex;
    align-items: center;
    margin-top: 1.2rem;

    &__Add {
      margin-right: 0.8rem;
      text-transform: capitalize;

      &.ant-btn {
        border: 1px solid #ffffff7f;
        border-radius: 4px;
      }
    }

    &__Del {
      padding: 0.2rem;
      border: 1px solid #ffffff7f;
      border-radius: 4px;
      font-size: 1.4rem;
      cursor: pointer;
    }

    &__Loading {
      border: none;
    }
  }
}
