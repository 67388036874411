.TaskScheduleDisplay {
  > *:first-child {
    color: $dark-gray;
  }
  > *:last-child {
    color: $black-99;
  }
  &.TaskScheduleDisplay--IsToday {
    > * {
      color: $light-green;
    }
  }
  &.TaskScheduleDisplay--IsPast {
    > * {
      color: $red;
    }
  }
}
