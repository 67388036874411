.CheckoutProjectTimeBar {
  @include checkoutTimeBarTooltip;
}

.CheckoutProjectTimeBarLoader {
  background-color: $white;
  border: 1px dotted $gray;
  height: 100%;
  width: 100%;
}
