.NextShiftScheduleEditedFlag {
  .PopoverContent {
    &__ChangeLog {
      &__Date {
        text-align: left;

        &:not(:last-child) {
          margin-bottom: 0.6rem;
        }

        &__Title {
          &__Text {
            @include openSansSemiBold;
            color: $white;
            font-size: 1rem;
            line-height: 1.4rem;
          }
        }

        &__Content {
          &__Item {
            @include openSansRegular;
            color: $white;
            font-size: 1rem;
            line-height: 1.4rem;

            &:not(:last-child) {
              margin-bottom: 0.5rem;
            }
            &:last-child {
              margin-bottom: 0.6rem;
            }

            &__UpdatedValue {
              color: $light-green;
            }
          }
        }
      }
    }
  }
}
