.AntButton {
  border: 1px solid #00000026;
  border-radius: 4px;
  font-weight: normal;

  &__Icon {
    cursor: pointer;
  }

  &-small {
    height: 2rem;
    font-size: 1.2rem;
    line-height: 1.7rem;
    padding: 0 0.7rem;
  }

  &-primary {
    border: none;
  }
}
