.CheckoutModal {
  &--LeaveTheOffice {
    width: 46.8521vw !important;
  }

  &--History {
    width: 72.3279vw !important;
    .ant-modal-content,
    .ant-modal-body {
      background: transparent !important;
    }
  }

  .ant-modal-content {
    padding: 0 !important;
  }

  .CheckoutPanel {
    &__Body {
      @include hideScrollBar;
      border-radius: 0.4rem;
    }
  }
}
