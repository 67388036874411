.VerticalMenuHeader {
  background: $black;
  height: 13rem;
  margin-bottom: $m-size;
  padding: 1rem;
  width: 100%;

  .VerticalMenuHeader__Top {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: $m-size;

    .VerticalMenuHeader__Top__Select {
      @include flexCenter();
      cursor: pointer;
      
      .VerticalMenuHeader__Top__Select__Avatar {
        margin-right: 1rem;
      }

      .VerticalMenuHeader__Top__Select__Text {
        @include flexCenter();
      }
    }
  }
}
