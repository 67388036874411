.CreateCheckout {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  width: 100%;

  > .anticon {
    font-size: 8rem;
    margin-bottom: 2.4rem;
  }

  &__Title {
    @include openSansSemiBold;
    font-size: 2rem;
    line-height: 2.7rem;
    margin-bottom: 1.6rem;
  }
  &__Message {
    margin-bottom: 2.4rem;
  }
  .CustomButton {
    font-size: 1.4rem;
    height: 4rem;
    line-height: 1.9rem;
    min-width: 14rem;
    padding: 1rem 1.6rem 1.1rem 1.6rem;
  }
}
