.ProjectCollapsePanelFooter {
  @include openSansSemiBold;
  align-items: center;
  border: 1px solid $gray;
  border-top: 0;
  border-bottom-left-radius: 0.4rem;
  border-bottom-right-radius: 0.4rem;
  color: $black;
  display: flex;
  font-size: 1.2rem;
  justify-content: flex-end;
  line-height: 1.7rem;
  padding: 1.2rem;

  &__Text {
    margin-right: 0.8rem;
  }
}
