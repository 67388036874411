.PersonalForm {
  &__Item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #f0f0f0;
    padding: 1.2rem 0;
    height: 5.6rem;

    &:last-child {
      border-bottom: none;
    }

    .ant-form-item {
      margin-bottom: 0;
    }

    .ant-select-selector {
      border-radius: 4px !important;
    }

    .ant-select-selection-placeholder {
      color: #000;
    }

    .ant-select-arrow {
      background-position: center;
      background-repeat: no-repeat;
      background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxLjVlbSIgaGVpZ2h0PSIxLjVlbSIgdmlld0JveD0iMCAwIDIwIDIwIj4KICAgIDxwYXRoIGZpbGw9Im5vbmUiIGQ9Ik0wLDBIMjBWMjBIMFoiIC8+CiAgICA8cGF0aCBkPSJNNywxMGw0LjE2Nyw0LjE2N0wxNS4zMzMsMTBaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMS4xNjcgLTEuNjY3KSIgLz4KICA8L3N2Zz4=");
    }

    .ant-select-arrow svg {
      visibility: hidden;
    }

    input {
      border-radius: 4px;
    }
  }

  &__Item-name {
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 1.36;
    margin-bottom: 0;
    flex: 0 0 10rem;
  }

  &__Item-value {
    flex: 0 1 44rem;
  }

  &__Item-input {
    width: 44rem;
    display: flex;
    justify-content: space-between;
  }

  &__Item-value {
    font-size: 1.4rem;
    line-height: 1.9rem;
    color: #000;
  }

  &__Radio {
    .RadioGroup__Option {
      padding: 0.6rem 1.2rem;
      font-size: 1.4rem;
      line-height: 1.9rem;
      width: fit-content;
    }
  }

  &--ReadOnly {
    border: 1px solid $gray;
    border-radius: 0.4rem;
    padding: 0 1.6rem;
  }
}
