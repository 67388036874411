.WorkspaceSideMenu {
  box-shadow: 0px 0px 3px $gray !important;
  border-radius: 0.4rem;
  padding: 1.6rem 0;

  &__Title {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 1.6rem;
    padding: 0 1.6rem;

    &__WorkspaceAvatar {
      margin-right: 0.8rem;
    }

    &__Text {
      @include openSansSemiBold;
      font-size: 1.4rem;
      line-height: 1.9rem;
    }
  }

  .ant-menu-item {
    .ant-menu-title-content {
      @include openSansRegular;
      align-items: center;
      display: flex;
      font-size: 1.4rem;
      justify-content: flex-start;
      line-height: 1.9rem;

      .anticon {
        color: $dark-gray;
        font-size: 2rem;
        margin-right: 1.2rem;
      }
    }
  }

  .ant-menu-item-selected {
    background-color: $off-white !important;
    color: $black !important;

    &::before {
      display: inline-block;
      content: "";
      height: 100%;
      width: 0.3rem;
      background-color: $light-green;
      position: absolute;
      left: 0;
    }

    &::after {
      content: none;
    }
  }
}
