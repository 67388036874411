.TimeSlot {
  &__Stripe {
    border: 1px solid var(--stripeColor);
    background: repeating-linear-gradient(
      45deg,
      var(--stripeColor),
      var(--stripeColor) 3px,
      #fff 3px,
      #fff 6px
    );
  }

  &__Tooltip {
    padding: 0;
  }
}
