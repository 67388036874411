.TeamPageObserverSelector {
  border-radius: 0.4rem;
  max-height: 43.9rem;
  overflow: auto;
  width: 36rem;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
  }
  &::-webkit-scrollbar:vertical {
    background-color: transparent;
    width: 1rem;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #d2d2d2;
    background-clip: padding-box;
    border-radius: 1rem;
    border: 0.4rem solid transparent;
  }
  &::-webkit-scrollbar-track {
    border-left: 0;
    background-color: transparent;
  }

  &__Search {
    padding: 1rem 0.8rem 1.2rem 0.8rem;

    &,
    &:hover,
    &:focus,
    &:active {
      border: 0;
      box-shadow: none;
    }
  }

  &__Selected,
  &__Members {
    &__Header {
      @include openSansSemiBold;
      align-items: center;
      border-top: 1px solid $gray;
      border-bottom: 1px solid $gray;
      display: flex;
      font-size: 1rem;
      justify-content: center;
      line-height: 1.4rem;
      padding: 0.5rem;
    }
    &__Body {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      height: fit-content;
      justify-content: flex-start;

      &--Empty {
        @include openSansRegular;
        align-items: center;
        font-size: 1.4rem;
        height: 11.9rem;
        justify-content: center;
        line-height: 1.9rem;
      }
    }
    &__Item {
      align-items: center;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 1rem;
      width: 100%;

      &__UserDetails {
        @include openSansRegular;
        align-items: center;
        display: flex;
        flex-direction: row;
        font-size: 1.4rem;
        gap: 0.8rem;
        line-height: 1.9rem;
        justify-content: flex-start;
      }

      &__AddBtn {
        @include openSansRegular;
        align-items: center;
        border: 1px solid $gray;
        display: flex;
        font-size: 1.2rem;
        height: 2rem;
        justify-content: center;
        line-height: 1.7rem;
        padding: 0.2rem 0.8rem 0.2rem 0.4rem;

        .anticon {
          color: $light-green;
          font-size: 1.6rem;
          height: 1.6rem;
        }
      }
      &__RemoveBtn {
        align-items: center;
        display: flex;
        height: 2rem;
        justify-content: center;
        padding: 0;
        width: 2rem;

        .anticon {
          color: $dark-gray;
          height: 2rem;
          font-size: 2rem;
        }
      }
    }
  }
}
