.EditableCell {
  &:nth-child(3) {
    width: 19.1259%;
  }
  &:nth-child(4) {
    width: 30.4684%;
  }

  &__wrap {
    border: 1px solid transparent;
    border-radius: 0.4rem;
    padding: 0.5rem 1rem;
    height: 3.2rem;
    cursor: pointer;

    &:hover {
      border: 1px solid #d9d9d9;
    }
  }
}
