.HistoryPanelHeader {
  align-items: center;
  background-color: $white;
  border-bottom: 1px solid $gray;
  display: flex;
  height: 5.6rem;
  justify-content: space-between;
  padding: 1.2rem 1.6rem;
  width: 100%;

  &__Title {
    @include openSansSemiBold;
    font-size: 1.6rem;
    line-height: 2.2rem;
  }

  &__Actions {
    align-items: center;
    display: flex;
    justify-content: flex-end;

    > * {
      &:not(:last-child) {
        margin-right: 1.6rem;
      }
    }
  }
}
