.DeleteShiftPopover {
  font-size: 1.2rem;
  line-height: 1.7rem;

  &__Title {
    margin-bottom: 0.8rem;
    font-weight: 600;
  }

  &__Time {
    margin-bottom: 1.2rem;
  }

  &__Btns {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  &__Cancel {
    margin-right: 0.8rem;
  }
}