.ActivityCardItemBody {
  background: $white;
  border: 1px solid $gray;
  border-radius: 0.4rem;
  padding: 1.6rem;
  width: 38.3rem;

  .ActivityCardItemBody__Header {
    align-items: center;
    display: flex;
    justify-content: space-between;

    .ActivityCardItemBody__Header__Title {
      @include openSansSemiBold;
      font-size: 1.4rem;
      margin-left: -1.6rem;
      padding-left: 1.6rem;

      &.ActivityCardItemBody__Header__Title--Editing {
        border-left: 3px solid $red;
      }
      &.ActivityCardItemBody__Header__Title--Confirmed {
        border-left: 3px solid $light-green;
      }
      &.ActivityCardItemBody__Header__Title--TeamDraft,
      &.ActivityCardItemBody__Header__Title--Draft {
        border-left: 3px solid $blue;
      }
    }
  }

  .ActivityCardItemBody__InputGroup {
    display: flex;
    flex-direction: column;
  }

  .ActivityCardItemBody__Footer {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    margin-top: 1.6rem;
    margin-left: auto;

    > *:not(:last-child) {
      margin-right: 0.6rem;
    }
  }
}
