.AccountSettingsModal {
  width: fit-content !important;

  .ant-modal-content {
    width: fit-content;
  }

  .AccountSettings {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;

    &__FormWrapper {
      width: 100%;
      margin-right: 2.4rem;
    }

    &__Title {
      text-transform: uppercase;
      font-size: 1.2rem;
      font-weight: 600;
      line-height: 1.7rem;
      letter-spacing: 0.9px;
      color: #000000;
      margin-bottom: 1.2rem;
    }

    &__Form {
      border: 1px solid #00000026;
      border-radius: 4px;
      padding: 0 1.6rem;
      margin-bottom: 2.4rem;
    }

    &__Avatar {
      flex: 0 0 12.8rem;
    }
  }
}
