.DeleteSectionButton {
  color: $dark-gray;
  padding: 0;
  width: 2.4rem;

  .anticon {
    font-size: 1.6rem;
  }

  &--Disabled {
    cursor: not-allowed;
  }
}
