.ProjectCardList {
  height: calc(100% - 10rem);
  overflow: auto;
  padding: 1.6rem;

  .ProjectCardItem {
    max-height: fit-content;

    &:not(:last-child) {
      margin-bottom: 1.6rem;
    }
  }
}
