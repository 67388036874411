.ProjectTotalTime {
  align-items: flex-start;
  background: white;
  border: 1px solid $gray;
  border-radius: 0.4rem;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  height: fit-content;
  justify-content: flex-start;
  padding: 1.6rem;

  .ProjectTimeBarContainer {
    margin-bottom: 0.4rem;
  }

  &__Time {
    @include openSansRegular;
    align-items: center;
    display: flex;
    font-size: 1.4rem;
    gap: 0.8rem;
    justify-content: flex-end;
    line-height: 1.9rem;
    width: 100%;
  }
}
