.ToggleWorkStatusBtn {
  @include grayBorder();
  @include flexCenter();
  height: 3.2rem;
  width: 100%;

  &__WorkingBtn,
  &__BreakBtn {
    align-items: center;
    background: $black;
    border: 1px solid #ffffff40;
    color: #ffffff40;
    display: flex;
    cursor: pointer;
    height: 3.2rem;
    font-size: 1.2rem;
    justify-content: center;
    line-height: 1.7rem;
    text-align: center;
    width: 50%;
  }

  &__WorkingBtn {
    border-bottom-left-radius: 0.4rem;
    border-top-left-radius: 0.4rem;

    &--Active {
      background-color: $light-green;
      color: $white;
      pointer-events: none;
    }

    &--Loading {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  &__BreakBtn {
    border-bottom-right-radius: 0.4rem;
    border-top-right-radius: 0.4rem;
    border-left: 0;
    position: relative;

    &--Active {
      background-color: $blue;
      color: $white;
      pointer-events: none;
    }

    &--Loading {
      opacity: 0.5;
      pointer-events: none;
    }

    &__Span {
      background: $blue;
      border: 1px solid $black;
      border-radius: 10pt;
      bottom: -0.8rem;
      font-size: 1rem;
      height: fit-content;
      left: 0;
      line-height: 1.4rem;
      margin: auto;
      padding: 0 0.8rem;
      position: absolute;
      right: 0;
      top: 2.5rem;
      width: 50%;
    }
  }
}
