.WorkHoursTooltip {
  font-size: 1.2rem;
  line-height: 1.7rem;
  color: #fff;
  padding: 0.2rem 0;
  width: 19.2rem;

  &__Row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.6rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__Info {
    display: flex;
    align-items: center;
    color: #4CE300;
  }

  &__Text {
    margin-left: 0.4rem;
  }
}
