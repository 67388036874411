.CreateActivityDraftDropdown {
  @include openSansRegular;
  align-items: center;
  background: $white;
  border: 1px solid $gray;
  border-radius: 0.4rem;
  box-shadow: 0px 0px 3px #00000026;
  cursor: pointer;
  display: flex;
  font-size: 1.2rem;
  height: 2.4rem;
  justify-content: space-between;
  width: 10.3rem;
  z-index: 1;

  .CreateActivityDraftDropdown__AddIcon {
    color: $light-green;
    font-size: 2.4rem;
  }
}

.CreateActivityDraftDropdown__Menu {
  border-radius: 0.4rem;
  box-shadow: 0px 0px 3px #00000026;

  .ant-dropdown-menu-item {
    > span {
      @include openSansRegular;
      font-size: 1.2rem;
    }
  }
}
