.CheckoutTaskCollapsePanel {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  > .ant-collapse,
  .CheckoutTaskCollapsePanelFooter {
    width: 100%;
  }

  > .ant-collapse {
    @include antCollapsePanelOverride;
    background-color: $white;
    border-radius: 0.4rem;

    > .ant-collapse-item {
      > .ant-collapse-header {
        padding: 1.6rem;
      }
      > .ant-collapse-content {
        > .ant-collapse-content-box {
          background-color: $off-white;
          padding: 2rem 1.6rem 2rem 4.9rem;
        }
      }
    }
  }
}
