.StartWorkTimeDisplay {
  @include openSansRegularSM();
  color: white;
  margin-bottom: 6.7rem;

  span {
    font-size: $jumbotron;
  }

  small {
    font-size: 9.6rem;
  }
}
