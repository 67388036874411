.Teammate {
  width: 100%;
  height: 5.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Teammate__Popover {
  padding-right: 0px !important;

  .ant-popover-inner-content {
    padding: 0;
  }
}
