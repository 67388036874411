.TaskSearchBar {
  border: 1px solid $gray;
  border-radius: 0.4rem;
  height: fit-content;
  position: relative;

  &--Searching {
    margin-top: -0.8rem;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    .TaskSearchBar__Header {
      padding-top: 0.8rem;
      padding-bottom: 1.1rem;
      position: relative;
    }

    .TaskSearchBar__Header__Input {
      border: none;
    }
  }

  &__Header {
    align-items: center;
    box-sizing: border-box;
    display: flex;
    justify-content: center;

    &__Input {
      @include openSansRegular();
      border: none !important;
      box-shadow: none !important;
      color: $dark-gray;
      font-size: 1.4rem;
      line-height: 1.9rem;
      padding: 0.6rem 0.8rem 0.7rem 0.8rem;
      height: 3.2rem;

      &::placeholder {
        color: $dark-gray;
      }
    }

    &__Actions {
      align-items: center;
      display: flex;
      justify-content: center;
      > * {
        margin-right: 1.2rem;
      }
    }

    .ant-popover {
      padding-top: 0;
      top: 5.2rem !important;
      width: 100%;

      > .ant-popover-content {
        > .ant-popover-inner {
          -webkit-box-shadow: none !important;
          box-shadow: none !important;

          > .ant-popover-inner-content {
            padding: 0;
          }
        }
      }
    }

    &__Actions__Close {
      border: 1px solid $gray;
      border-radius: 0.4rem;
      padding: 0.5rem;
    }

    // > div:nth-last-child(2) {
    //   border: 2px solid red;
    //   width: 100%;
    //   > div {
    //     width: 100%;
    //   }
    // }
  }

  &__Body {
    @include hideScrollBar;
    align-items: flex-start;
    border-bottom: 1px solid $gray;
    border-bottom-left-radius: 0.4rem;
    border-bottom-right-radius: 0.4rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-left: -1px;
    margin-top: -1px;
    max-height: 53.2rem;
    overflow-y: scroll;
    position: relative;
    width: calc(100% + 0.2rem);

    &--Loading {
      align-items: center;
      border: 1px solid $gray;
      display: flex;
      justify-content: center;
      padding: 1.1rem 0;
    }
  }
}
