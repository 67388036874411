.WorkspaceMemberAccountForm {
  @include memberForm;

  .SampleClass {
    @include openSansRegular;
    color: $blue;
    cursor: pointer;
    font-size: 1.4rem;
    line-height: 1.9rem;
    position: absolute;
    right: 1.6rem;
    top: 1.8rem;
  }

  &__TeamPages {
    &__Popover {
      margin-bottom: 0;

      .ant-popover-inner {
        box-shadow: 0px 0px 3px $gray;
        .ant-popover-inner-content {
          padding: 0;
        }
      }
    }

    > .ant-form-item-control {
      @include openSansRegular;
      color: $blue;
      cursor: pointer;
      font-size: 1.4rem;
      line-height: 1.9rem;
    }
  }
}
