.ShiftTimePicker {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;

  &__Column {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  &__Divider {
    line-height: 3.2rem;
  }

  &__Text {
    font-size: 1.4rem;
    line-height: 1.9rem;
    color: #0000007f;
    margin-bottom: 1.2rem;
  }

  .ant-picker {
    width: 9.6rem;
    border-radius: 4px;

    input {
      text-align: center;
    }
  }

  &-small {
    .ShiftTimePicker {
      &__Divider {
        line-height: 2rem;
      }

      &__Text {
        font-size: 1.2rem;
        line-height: 1.7rem;
        margin-bottom: 0.6rem;
      }
    }
    .ant-picker {
      width: 5.6rem;
      height: 2rem;
      padding: 0;
      font-size: 1.2rem;
      line-height: 1.7rem;
    }
  }

  &__Error {
    border-color: #ff2900;
    box-shadow: none;

    .ant-popover-inner {
      border-radius: 4px;
    }

    .ant-popover-inner-content {
      width: max-content;
      max-width: 22.8rem;
      padding: 0;
    }

    &:hover {
      border-color: #ff2900;
      box-shadow: none;
    }
  }

  &__ErrorMsg {
    padding: 0.8rem;
    color: #ff2900;
    background-color: #ffe9e5;
    font-size: 1.2rem;
    line-height: 1.7rem;
    border: 1px solid #ff2900;
    border-radius: 4px;
  }
}
