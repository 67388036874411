.SelectTaskDesinationPopoverContent {
  min-height: 32.2rem;
  min-width: 32rem;

  &__Header {
    @include openSansSemiBold;
    border-bottom: 1px solid $gray;
    font-size: 1.4rem;
    height: 3.2rem;
    line-height: 1.9rem;
    padding: 0.6rem 1.6rem 0.7rem 1.6rem;
  }

  &__Body {
    padding: 1.6rem;

    &__Selection {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      justify-content: center;
      min-height: 5.9rem;

      > * {
        margin-bottom: 1.6rem;
      }
    }

    &__Actions {
      align-items: center;
      display: flex;
      justify-content: flex-end;

      > * {
        @include openSansRegular;
        display: flex;
        font-size: 1.4rem;
        height: 3.2rem;
        line-height: 1.9rem;
        padding: 0.7rem 1.4rem 0.6rem 1.5rem;
        width: 7.2rem;

        &:not(:last-child) {
          margin-right: 1.6rem;
        }
      }

      &__Cancel {
        background-color: #fcfcfc !important;
        color: $black !important;
      }
      &__Action {
        background-color: $blue !important;
        color: $white !important;
        text-transform: capitalize;

        &--Disabled {
          background-color: $gray !important;
          color: $black !important;
          cursor: not-allowed;
          opacity: 0.5;
        }
      }
    }
  }
}
