.TaskDetails {
  @include openSansRegularSM();
  background: $white;
  color: $dark-gray;
  display: flex;
  min-width: 48rem;
  width: 35.13909vw;
  flex: 1;
  flex-direction: column;

  .TaskDetails__Header {
    height: 4rem;
    width: 100%;

    .TaskDetails__Header__Title {
      @include openSansBold();
      overflow: hidden;
      padding: $m-size $m-size 0 $m-size;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;
    }
  }

  .TaskDetails__Tabs {
    height: 100%;
    position: relative;

    .ant-tabs-nav {
      height: 4rem;
      margin: 0;
      padding: 0 1.2rem;

      .ant-tabs-nav-wrap {
        .ant-tabs-nav-list {
          .ant-tabs-tab {
            margin: 0;
            text-transform: capitalize;
          }
        }
      }
    }

    .ant-tabs-content-holder {
      > .ant-tabs-content {
        height: 100%;
      }
    }
  }
}
