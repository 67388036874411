.UnreadTaskActivities {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &__Loading {
    margin: 2rem auto;
  }

  &__Item {
    &__Header {
      align-items: center;
      display: flex;
      justify-content: flex-start;
      padding: 3.2rem;
      position: relative;

      &:before {
        content: "";
        border-top: 1px solid darken($color: $light-gray, $amount: 15%);
        left: 0;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
        z-index: 0;
      }
      .UnreadTaskActivities__Item__Header__Title {
        @include openSansSemiBold;
        align-items: center;
        background: $white;
        border-radius: 1.2rem;
        box-shadow: 0px 0px 3px $gray;
        display: flex;
        font-size: 1.2rem;
        height: 2.4rem;
        justify-content: center;
        left: 50%;
        padding: 0.3rem 1.2rem 0.4rem 1.2rem;
        position: absolute;
        text-align: center;
        transform: translateX(-50%);
        width: fit-content;

        .UnreadTaskActivities__Item__Header__Title__DraftIcon {
          color: $blue;
        }
      }
    }

    &__Body {
      .ActivityCardItem:first-child {
        padding-top: 0;
      }
      .ActivityCardItem:last-child {
        padding-bottom: 2rem;
      }
    }
  }

  &__MarkAllAsRead {
    align-items: center;
    background: $white;
    border: 1px solid $gray;
    border-radius: 0.4rem;
    cursor: pointer;
    display: flex;
    height: 3.2rem;
    justify-content: center;
    margin-bottom: 2rem;
    margin-left: 6.3rem;
    width: 38.3rem;

    > span {
      @include openSansRegular;
      font-size: 1.2rem;
      line-height: 1.7rem;
    }
  }

  &__NoActivities {
    @include openSansRegular;
    background-color: #f2f9ff;
    color: #000000cc;
    font-size: 1.2rem;
    margin: 0 auto;
    padding: 1.2rem 0 1.1rem 0;
    text-align: center;
    width: 100%;
  }
}
