.ManageTaskTimePopoverContent {
  @include grayBorder();
  align-items: center;
  background: $white;
  border-radius: 0.4rem;
  box-shadow: 0 0 0.3rem $gray;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 13.6rem;
  min-width: 27rem;
  overflow-x: visible;
  padding: 1.6rem;

  > * {
    align-items: center;
    border-radius: 0.4rem;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
  }

  > *:not(:last-child) {
    margin-bottom: 1.6rem;
  }

  &__Actions {
    @include openSansRegular;
    align-items: center;
    border: none;
    display: flex;
    font-size: 1rem;
    justify-content: space-between;
    line-height: 1.4rem;

    > * {
      @include grayBorder();
      align-items: center;
      border-radius: 0.4rem;
      cursor: pointer;
      display: flex;
      justify-content: center;
      min-height: 2.4rem;
      min-width: 5.2rem;
      white-space: nowrap;
    }

    > *:not(:last-child) {
      margin-right: 0.6rem;
    }

    &__Decrement {
      color: $red;
    }

    &__Increment {
      color: $light-green;
    }
  }

  &__AddAllUnallocatedTime,
  &__Done {
    @include openSansRegular;
    cursor: pointer;
    font-size: 1.2rem;
    line-height: 1.7rem;
    padding: 0.3rem 0;

    > * {
      margin: 0 auto;
    }
  }

  &__AddAllUnallocatedTime {
    @include grayBorder();
  }

  &__Done {
    background-color: $blue;
    color: $white;
  }
}
