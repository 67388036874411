.FilterTasksButtonPopoverContent {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  justify-content: center;
  width: 32rem;
  padding: 1.6rem;

  > * {
    width: 100%;
  }

  &__Item {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &__Title {
      @include openSansSemiBold;
      font-size: 1.4rem;
      line-height: 1.9rem;
    }
  }
}
