.InTheOfficeBtnPopoverContent {
  &__Card {
    align-items: center;
    background-color: $white;
    border: 1px solid $gray;
    border-radius: 0.4rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1.2rem;

    &:not(:last-child) {
      margin-bottom: 0.6rem;
    }
  }

  .InTheOfficeBtnPopoverContent__Time {
    @include openSansRegular;
    align-items: center;
    border: 1px solid $gray;
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    font-size: 1.4rem;
    margin-bottom: $s-size;
    padding: 1.2rem;
    width: 100%;

    &__Item {
      align-items: center;
      border-bottom: none;
      display: flex;
      justify-content: space-between;
      width: 100%;

      &:not(:last-child) {
        margin-bottom: 0.8rem;
      }

      &--Work {
        .InTheOfficeBtnPopoverContent__Time__Item__Time {
          color: $light-green;
        }
      }

      &--Break {
        .InTheOfficeBtnPopoverContent__Time__Item__Time {
          color: $blue;
        }
      }
    }

    &-Loading {
      margin-bottom: $s-size;
    }
  }
  .InTheOfficeBtnPopoverContent__Checkout {
    @include openSansRegular;
    align-items: center;
    border: 1px solid $gray;
    border-radius: 0.5rem;
    cursor: pointer;
    display: flex;
    font-size: 1.2rem;
    justify-content: space-between;
    line-height: 1.7rem;
    padding: 0.8rem;
    width: 100%;

    > span {
      margin: auto;
    }

    .CheckoutStatusIcon {
      color: $red;

      &__Icon {
        font-size: 1.6rem !important;
      }
    }
  }

  .CheckoutModalToggleBtn {
    width: 100%;
  }
}
