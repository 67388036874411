.ProjectCollapse-wrapper {
  overflow: hidden;
  opacity: 1;
  transition: all 0.15s;

  &-opacity {
    opacity: 0;
  }

  &-collapse {
    height: 0 !important;
  }
}

.ProjectCollapse {
  margin-bottom: 1.6rem;
  border-radius: 0.4rem;

  .ant-collapse-content > .ant-collapse-content-box {
    padding: 0;
    background: #f7f7f7;
    border-radius: 0.4rem;
  }

  .ant-collapse-item:last-child > .ant-collapse-content {
    border-bottom-left-radius: 0.4rem;
    border-bottom-right-radius: 0.4rem;
  }

  &__subCollapse {
    border: none;

    .ant-collapse-content > .ant-collapse-content-box {
      padding: 2rem 1.6rem;
    }
  }

  &__panel:last-child,
  &__task-panel:last-child {
    border-radius: 0.4rem !important;
  }

  &__panel,
  &__task-panel {
    background-color: #fff;
  }

  &__panel.ant-collapse > .ant-collapse-item:last-child {
    border-radius: 0.4rem;
  }

  &__task-panel.ant-collapse-item:last-child {
    border-bottom: 0;
  }
}
