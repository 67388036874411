.UnreadActivitiesCount {
  &--NoIcon {
    align-items: center;
    background-color: #0000000d;
    border-radius: 0.4rem;
    display: flex;
    height: 2.4rem;
    justify-content: center;
    padding: 0.4rem 1.1rem 0.3rem 1.1rem;

    .UnreadActivitiesCount__Count {
      @include openSansRegular;
      color: #00000099;
      font-size: 1.2rem;
      line-height: 1.7rem;
    }
  }

  &--Icon {
    .UnreadActivitiesCount__Count {
      color: $red;

      > .anticon {
        color: $red;
        margin-right: 0.6rem;
      }
    }
  }

  &__Count {
    align-items: center;
    display: flex;
    justify-content: flex-start;
  }
}
