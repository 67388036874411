.TaskAssignees {
  align-items: center;
  align-self: flex-end;
  display: flex;
  gap: 1.2rem;
  height: 2rem;
  justify-content: right;

  &__ContributorsSearcher {
    z-index: 1080 !important;
    .ant-popover-inner-content {
      padding: 0;
    }
  }

  &__Empty {
    padding: 0.2rem;
    border: 1px solid #00000026;
    border-radius: 50%;
  }
}
