.WatchDisplay {
  &--Active {
    > .anticon {
      color: $light-green !important;
    }
  }

  > .anticon {
    font-size: 1.6rem;
  }
}
