.ProjectModal {
  &__Content {
    width: 100%;
    height: 100%;
    display: flex;
  }

  &__Left {
    display: flex;
    flex: 1 1;
    justify-content: center;
    align-items: center;
    padding: 2rem;
  }

  &__Right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex: 1 1;
    background-color: #f7f7f7;
  }
}
