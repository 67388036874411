.DroppableItem {
  position: relative;

  &--DraggingOver {
    // style when dragging item over droppable
  }

  &__DroppablePlaceholder {
    background-color: $gray;
    position: absolute;
  }
}
