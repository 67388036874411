.OverviewHeader {
  width: 100%;
  padding: 1.2rem 1.6rem;
  height: 5.6rem;
  background-color: #fff;
  border-bottom: 1px solid $gray;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__Info {
    display: flex;
    align-items: center;

    > span {
      font-size: 1.6rem;
      font-weight: 600;
      line-height: 1.375;
      margin-left: 1.2rem;
    }
  }

  &__Operation {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3.2rem;
    height: 3.2rem;
    background: #ffffff;
    border: 1px solid #00000026;
    border-radius: 4px;
    cursor: pointer;
  }

  &__Menu {
    .ant-dropdown-menu-item {
      font-size: 1.4rem;
      line-height: 1.9rem;
    }
  }
}
