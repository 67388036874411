.WorkTimes {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 2.7rem;

  .CheckoutItem {
    margin-right: 2.4rem;

    &:last-child {
      margin-right: 0;
    }
  }

  &__WorkHours {
    flex: 0 0 12.8rem;
  }

  &__Content {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2.4rem;
    line-height: 3.3rem;
  }

  &__Card {
    min-width: 16.7rem;
  }

  &__Card__Empty {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  &__LoadingIcon {
    font-size: 1.4rem;
  }

  &__Action {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2rem;
    height: 2rem;
    padding: 0.2rem;
    border: 1px solid #00000026;
    border-radius: 4px;
    cursor: pointer;

    &__Warning {
      color: #ff2900 !important;
      font-size: 1.4rem;
    }

    &__Refresh {
      color: #0000007f;
      font-size: 1.4rem;

      &-loading {
        animation-name: spin;
        animation-duration: 1000ms;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
      }
    }
  }
}
