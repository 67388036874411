.GetStartedMessage {
  display: flex;
  align-items: flex-start;
  margin-bottom: 2.4rem;
  padding: 1.6rem 1.8rem;
  border-radius: 4px;
  background-color: #f7f7f7;
  color: #000;
  overflow: hidden;
  opacity: 1;
  transition: all 0.15s;

  &-note {
    flex: 0 0 2rem;
    font-size: 2rem;
    color: #0079f2;
  }

  &-close {
    flex: 0 0 2rem;
    cursor: pointer;
  }

  &-info {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 1.4rem;
    margin-right: 2.4rem;
  }

  &-title {
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 1.9rem;
    margin-bottom: 0.8rem;
  }

  &-content {
    font-size: 1.4rem;
    line-height: 2.1rem;
  }

  &-opacity {
    opacity: 0;
  }

  &-collapse {
    height: 0 !important;
    margin-bottom: 0;
    padding: 0;
  }
}
