.MergeTaskModal {
  &__Steps {
    width: 100%;

    &__SelectTask,
    &__RemoveTask {
      @include openSansRegular;
      color: $blue;
      cursor: pointer;
      font-size: 1.2rem;
      line-height: 1.7rem;
    }

    &__ContentSelector {
      > .ant-checkbox-group {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        height: 10.8rem;
        justify-content: flex-start;

        > .ant-checkbox-group-item {
          @include openSansRegular;
          box-sizing: border-box;
          font-size: 1.4rem;
          flex-grow: 0;
          flex-shrink: 1;
          flex-basis: auto;
          height: 3.6rem;
          line-height: 2.1rem;
          padding: 0.8rem 0;
        }
      }
    }

    &__ResultTask {
      border-radius: 0.4rem;
      padding: 1.6rem;

      &--Deleted {
        background-color: #ff29000d;
        margin-bottom: 1.7rem;

        .MergeTaskModal__Steps__ResultTask__Title {
          color: $red;
        }
      }

      &--New {
        background-color: #f7fcf4;

        .MergeTaskModal__Steps__ResultTask__Title {
          color: $light-green;
        }
      }

      &__Title {
        margin-bottom: 1.7rem;

        > .anticon {
          font-size: 2rem;
          margin-right: 0.8rem;
        }
      }
    }

    &__Actions {
      > * {
        &:not(:last-child) {
          margin-right: 1.4rem;
        }
      }
    }

    > .ant-steps {
      > .ant-steps-item {
        &:not(:first-child):not(:nth-child(3)) {
          .SearchTaskCardItem {
            border-radius: 0.4rem;
            pointer-events: none;
          }
        }

        &-disabled {
          .ant-steps-item-description {
            pointer-events: none;
          }
        }

        > .ant-steps-item-container {
          > .ant-steps-item-content {
            overflow: visible;

            > .ant-steps-item-title {
              @include openSansSemiBold;
              align-items: center;
              display: flex;
              font-size: 1.4rem;
              justify-content: space-between;
              line-height: 1.9rem;
              margin-top: 0.8rem;
              margin-bottom: 1.7rem;
            }
            > .ant-steps-item-description {
              align-items: flex-start;
              display: flex;
              flex-direction: column;
              float: right;
              gap: 1.7rem;
              justify-content: center;
              width: 91.8938%;

              > * {
                width: 100%;
              }

              > .SearchTaskCardItem {
                border: 1px solid $gray;
                border-radius: 0.4rem;
              }
            }
          }
        }
      }
    }
  }

  &__Instructions {
    @include openSansRegular;
    align-items: center;
    background-color: $off-white;
    border-radius: 0.4rem;
    color: $black;
    display: flex;
    font-size: 1.4rem;
    justify-content: flex-start;
    line-height: 1.9rem;
    padding: 1.8rem;
  }
}
