.WorkDayDetailedHours {
  @include workTimeWidget;
  flex-grow: 1;

  .WorkDayDetailedHours__Content__Data {
    justify-content: flex-start;

    > span {
      white-space: nowrap;

      &:first-child {
        margin-right: 4.8rem;
        width: 7.7rem;
      }
      &:nth-child(2) {
        margin-right: 1.6rem;
        width: 4.9rem;
      }
    }
    &__TotalTimeBar {
      align-items: center;
      background-color: #0000001a;
      display: flex;
      height: 0.6rem;
      justify-content: flex-start;
      flex-grow: 1;

      &__AllocatedTime {
        background-color: $blue;
        height: 100%;
      }

      &__UnallocatedTime {
        background-color: $warning;
        height: 100%;
      }
    }
  }
}
