.AddSectionInput {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__Input {
    margin-right: 1rem;
    border-radius: 0.4rem;
  }

  &__Button {
    background-color: #40BF00;

    &:focus,
    &:hover {
      background-color: lighten(#40BF00, 5%);
    }
  }
}