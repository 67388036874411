.Confirm {
  z-index: 1033;
  
  .ant-popover-placement-bottom {
    padding-top: 0.2rem;
  }

  .ant-popover-placement-top {
    padding-bottom: 0.2rem;
  }

  .ant-popover-inner-content {
    width: 20rem;
    padding: 1.2rem;
  }

  &__Popover {
    font-size: 1.2rem;
    line-height: 1.7rem;
  }

  &__Content {
    margin-bottom: 1rem;
  }

  &__Btns {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  &__Cancel {
    margin-right: 0.8rem;
  }
}
