.TaskListTable {
  &__Empty {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #00000026;
    border-radius: 4px;
    padding: 2.6rem;
    font-size: 1.4rem;
    line-height: 1.5;
  }

  &__SearchBar {
    z-index: 1033;
  }

  .ant-switch-checked {
    background-color: #40bf00;
  }

  .ant-empty {
    .ant-empty-img-simple,
    .ant-empty-description {
      display: none !important;
    }
  }
}
