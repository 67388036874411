.ProjectCardItem {
  background-color: $white;
  border-radius: 0.4rem;
  border-bottom: 0 !important;

  > .ant-collapse-item {
    border-bottom: 0 !important;

    > .ant-collapse-header {
      border: 1px solid $gray;
      border-radius: 0.4rem !important;
      padding: 1.6rem 0.6rem 1.6rem 1.6rem !important;
    }

    > .ant-collapse-content {
      > .ant-collapse-content-box {
        border: 0 !important;
        padding: 0;
      }
    }

    &-active {
      > .ant-collapse-header {
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
      }

      .ProjectCardItem__Header__Title {
        > .anticon {
          transform: rotate(0deg);
        }
      }
    }
  }

  &__Header {
    align-items: center;
    display: flex;
    justify-content: space-between;

    &__Title {
      align-items: center;
      display: flex;
      justify-content: flex-start;

      .ProjectInitial {
        margin-right: 0.8rem;
      }

      &__Text {
        @include openSansSemiBold;
        font-size: 1.4rem;
        line-height: 1.9rem;
      }

      > .anticon {
        font-size: 2rem;
        transition: transform 0.3s;
        transform: rotate(-90deg);
      }
    }

    &__Actions {
      align-items: center;
      display: flex;
      justify-content: flex-end;

      &__TimeTracker {
        align-items: center;
        border: 1px solid $gray;
        border-radius: 0.4rem;
        display: flex;
        height: 2.4rem;
        justify-content: center;
        margin-right: 0.8rem;
        padding: 0;
        width: 2.4rem;

        > .anticon {
          color: $dark-gray;
          font-size: 2rem;
        }
      }
    }

    // &__Wrapper {
    //   align-items: center;
    //   display: flex;
    //   justify-content: right;
    //   position: relative;

    //   > *:not(:last-child) {
    //     margin-right: 0.4rem;
    //   }

    //   &__TimeTracker,
    //   &__Details {
    //     @include grayBorder();
    //     align-items: center;
    //     border-radius: 0.4rem;
    //     color: $dark-gray;
    //     cursor: pointer;
    //     display: flex;
    //     height: 2.4rem;
    //     padding: 0.2rem;
    //     justify-content: center;
    //     width: 2.4rem;
    //   }
    // }
  }

  &__TaskList {
    &--Loading {
      align-items: flex-start;
      border: 1px solid $gray;
      border-top: 0;
      display: flex;
      justify-content: center;
      padding: 1.6rem;
    }

    .TaskCardItem {
      &:first-child {
        border-radius: 0;
        border-top: 0;
      }
    }
    .CardEmptyState {
      border-radius: 0;
      border-top: 0;
    }
  }

  .ProjectCardItem__TasksNotInFolderList {
    margin-top: $m-size;
  }
}
