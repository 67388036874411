.TaskCardItem {
  @include grayBorder();
  align-items: flex-start;
  background: $white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  cursor: pointer;
  padding: 0.9rem 1.2rem 1.2rem 4.8rem;
  position: relative;

  &__Row {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;

    &:nth-last-child(2) {
      margin-top: 1.2rem;
    }

    &:nth-child(2):not(:last-child) {
      margin-top: 0.8rem;
    }

    .Priority {
      margin-left: -2.5rem;
      margin-right: 0.8rem;
    }

    &__Actions {
      align-items: center;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
    }

    > * {
      &:last-child:not(:first-child) {
        margin-left: auto;
      }
    }

    .ArchivedTag {
      @include openSansRegular;
      align-items: center;
      color: $dark-orange;
      display: flex;
      font-size: 1rem;
      justify-content: center;
      line-height: 1.4rem;
      padding: 0.2rem 0.4rem;

      > .anticon {
        font-size: 1.6rem;

        + span {
          margin-left: 0.4rem;
        }
      }
    }
  }

  .TaskAssignees {
    bottom: 1.2rem;
    position: absolute;
    right: 1.2rem;
  }

  &--DraggingPlaceholder {
    background-color: lighten($color: $gray, $amount: 75%);
  }

  &--IsDragging {
    // style when draggable is being dragged
    border: 1px solid $gray !important;
    border-radius: 0.4rem;
    user-select: none;
  }
}
