.ListUsersTaskTimeInWorkDayAndTask {
  align-items: center;
  display: flex;
  justify-content: flex-start;

  .ItemUsersTaskTimeInWorkDayAndTask {
    &:not(:last-child) {
      margin-right: 1.2rem;
    }
  }
}
