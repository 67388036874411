.SectionSelection {
  @include selection;

  &__Item {
    &:last-child {
      width: 7.2rem;

      .SectionSelection__Item__Dropdown__Selected {
        max-width: 4.2rem;
      }
    }
  }
}
