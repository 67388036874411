.CheckoutQuickLinks {
  align-items: center;
  display: flex;
  width: 23.6rem;
  justify-content: space-between;

  &__Link {
    @include openSansRegular;
    background-color: $white;
    border: 1px solid $gray;
    border-radius: 0.4rem;
    cursor: pointer;
    font-size: 1.2rem;
    line-height: 1.7rem;
    padding: 0.1rem 0.8rem 0.2rem 0.8rem;
    white-space: nowrap;
  }
}
