.ScheduleTask {
  @include flexCenter();
  @include controlIcon();
  position: relative;
}

.ScheduleTask__Popover {
  height: 0;
  padding: 0;
  position: relative;
  width: 0;

  .ant-popover-inner-content {
    padding: 0;
  }
}
