.Activity {
  color: #000;
  height: fit-content;
  margin-bottom: 0;
  overflow: hidden;
  opacity: 1;
  transition: all 0.15s;
  flex-wrap: nowrap;

  &-opacity {
    opacity: 0;
  }

  &-collapse {
    height: 0 !important;
  }

  &__avatar {
    width: 3.2rem;
    height: 3.2rem;
    margin-right: 1.3rem;
  }

  &__content {
    width: 100%;
  }

  &__name {
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 2.7rem;
    margin-right: 8px;
  }

  &__time {
    font-size: 1.2rem;
    line-height: 1.42;
    margin-right: 0.8rem;
  }

  &__tag {
    padding: 0.1rem 0.8rem;
    border-radius: 0.4rem;
    font-size: 1.2rem;
    color: #ff2900;
    background-color: rgba(255, 41, 0, 0.1);
  }

  &__operations {
    opacity: 0;
  }

  &__operations-show {
    opacity: 1;
  }

  &__operation {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.4rem 0.8rem;
    margin-left: 1.2rem;
    background: #ffffff;
    border: 1px solid #00000026;
    border-radius: 0.4rem;
    font-size: 1.2rem;
    line-height: 1.7rem;
    color: #000000;
    cursor: pointer;
  }

  &__link {
    cursor: not-allowed;
  }

  &__icon {
    font-size: 1.6rem;
  }
}
