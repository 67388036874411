.ProjectArchiveToggler {
  &__Button {
    @include openSansRegular;
    align-items: center;
    cursor: pointer;
    display: flex;
    font-size: 1.2rem;
    justify-content: flex-start;
    line-height: 1.7rem;
    padding: 0.6rem 1.2rem 0.6rem 0.8rem;

    .anticon {
      color: $dark-gray;
      font-size: 2rem;
      margin-right: 0.8rem;
    }
  }

  &__Modal {
    @include customModal;
    padding-bottom: 0;

    &__Container {
      align-items: flex-start;
      background: $white;
      border-radius: 0.4rem;
      display: flex;
      flex-direction: column;
      gap: 2.7rem;
      justify-content: space-between;
      padding: 2.4rem;

      &__Icon {
        margin-bottom: 3.6rem;
      }

      &__Message {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        gap: 2.4rem;
        justify-content: flex-start;

        &__Title {
          @include openSansSemiBold;
          font-size: 1.8rem;
          line-height: 2.4rem;
        }
        &__Text {
          @include openSansRegular;
          align-items: flex-start;
          display: flex;
          flex-direction: column;
          font-size: 1.4rem;
          gap: 2.7rem;
          justify-content: flex-start;
          line-height: 1.9rem;

          .ant-checkbox-checked .ant-checkbox-inner {
            background-color: $light-green;
            border-color: $light-green;
          }
        }
      }

      &__Action {
        align-items: center;
        display: flex;
        justify-content: flex-end;
        gap: 1.6rem;
        text-align: right;
        width: 100%;

        > .ant-btn-primary {
          align-items: center;
          display: flex;
          justify-content: center;
          padding: 0.6rem 1.2rem 0.6rem 1.2rem;
          min-width: 7.2rem;
        }
      }
    }
  }
}
