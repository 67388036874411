.SubmittedUnconfirmedActivities {
  &__Header {
    align-items: center;
    display: flex;
    height: 6rem;
    justify-content: flex-start;
    padding: 1.8rem;
    position: relative;

    &:before {
      content: "";
      border-top: 1px solid darken($color: $light-gray, $amount: 15%);
      left: 0;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 100%;
      z-index: 0;
    }
    &__Title {
      @include openSansSemiBold;
      align-items: center;
      background: $white;
      border-radius: 1.2rem;
      box-shadow: 0px 0px 3px $gray;
      display: flex;
      font-size: 1.2rem;
      height: 2.4rem;
      justify-content: space-between;
      left: 50%;
      padding: 0.3rem 0.6rem 0.4rem 0.6rem;
      position: absolute;
      text-align: center;
      transform: translateX(-50%);
      width: fit-content;

      > span {
        margin-right: 0.6rem;
      }

      &__DraftIcon {
        color: $blue;
        margin-right: 0.6rem;
      }
    }
  }

  &__Body {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &__Loading {
      margin: 1.6rem auto;
    }

    .ActivityCardItem {
      background-color: #f2f9ff;
      width: 100%;
    }

    &__Empty {
      @include openSansRegular;
      background-color: #f2f9ff;
      color: #000000cc;
      font-size: 1.2rem;
      margin: 0 auto;
      padding: 1.2rem 0 1.1rem 0;
      text-align: center;
      width: 100%;
    }
  }
}
