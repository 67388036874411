.SearchTaskCardItem {
  @include grayBorder();
  background-color: $white;
  border-top: none;
  min-height: 9.3rem;
  padding: 1.2rem;

  &--Hovered {
    background-color: $off-white;
  }

  &--Merge {
    cursor: pointer;
  }

  &__Header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.8rem;
    position: relative;
    width: 100%;

    &__Left {
      align-items: center;
      display: flex;
      justify-content: left;
      position: relative;
      width: 100%;

      .Priority {
        margin-right: 0.8rem;
      }

      &__Title {
        @include openSansRegular();
        color: $black;
        font-size: $font-size-small;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 65%;

        mark {
          background-color: $text-highlight;
          padding-left: 0;
          padding-right: 0;
        }
      }
    }

    &__Right {
      align-items: center;
      background-color: $white;
      display: flex;
      justify-content: center;
      position: absolute;
      right: 0;
      top: 0;

      .SearchTaskCardItem__Header__Right__Hover {
        align-items: center;
        display: flex;
        justify-content: center;

        .SearchTaskCardItem__Header__Right__Hover__Icon {
          @include grayBorder();
          border-radius: 0.4rem;
          color: $dark-gray;
          cursor: pointer;
          height: 2.4rem;
          margin-right: 0.6rem;
          padding: 0.1rem;
          width: 2.4rem;

          &.SearchTaskCardItem__Header__Right__Hover__Icon--IsToday {
            color: $light-green;
          }

          > * {
            height: auto;
            width: 100%;
          }
        }
      }
    }
  }

  .TaskDestinationDisplay {
    margin-bottom: 1.2rem;
    margin-left: 2.5rem;
  }

  &__Footer {
    align-items: center;
    display: flex;
    height: fit-content;
    justify-content: space-between;
    margin-left: 2.3rem;
  }
}
