.ProjectSelectionDropdownMenu {
  max-width: 28.8rem;

  &__Item {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    gap: 0.8rem;
    width: 100%;

    &__Title {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &--Loading {
      align-items: center;
      display: flex;
      justify-self: center;
      width: 100%;

      > .ant-menu-title-content {
        align-items: center;
        display: flex;
        justify-self: center;
        text-align: center;
        width: 100%;

        > .anticon-loading {
          margin: 0 auto;
        }
      }
    }

    > .ant-menu-title-content {
      align-items: center;
      display: flex;

      .ProjectInitial {
        margin-right: 0.8rem;
      }
    }
  }
}
