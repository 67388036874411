.TeamMemberSelector {
  align-items: flex-start;
  display: flex;
  border-bottom: 1px solid $gray;
  justify-content: space-between;
  padding: 1.2rem 1.6rem;
  width: 100%;

  > .StarredTeamMemberList {
    margin-right: 1.6rem;
  }

  &--MemberSelected {
    justify-content: flex-end;
  }

  &__Actions {
    align-items: center;
    display: flex;
    justify-content: flex-end;

    > * {
      align-items: center;
      border: 1px solid $gray;
      border-radius: 0.4rem;
      display: flex;
      height: fit-content;
      justify-content: center;
      padding: 0.4rem !important;
      width: fit-content;

      > .anticon {
        color: $dark-gray;
        font-size: 2.2rem;
      }

      &:not(:last-child) {
        margin-right: 1.6rem;
      }

      .ant-popover-content {
        width: 100%;
        .ant-popover-inner-content {
          padding: 0.4rem 0 0.8rem 0;
        }
      }
    }
  }
}
