.WorkDayCheckoutStatusPopoverContent {
  background-color: $black;
  border-radius: 0.4rem;
  padding: 0.8rem;
  width: 21.08345vw;

  &__Header {
    @include openSansSemiBold;
    align-items: center;
    color: $white;
    display: flex;
    font-size: 1.2rem;
    justify-content: flex-start;
    line-height: 1.7rem;
    margin-bottom: 0.8rem;

    > .anticon {
      font-size: 1.6rem;
      margin-right: 0.6rem;
    }

    .WorkDayCheckoutStatus__StillInOffice {
      color: $white;
    }
  }

  &__Body {
    @include openSansRegular;
    align-items: flex-start;
    color: $white;
    display: flex;
    flex-direction: column;
    font-size: 1.2rem;
    justify-content: flex-start;
    line-height: 1.7rem;
    padding-left: 3rem;

    &__FailedCheckoutItem {
      &:not(:last-child) {
        margin-bottom: 0.4rem;
      }
    }
  }
}
