.DndTable {
  border: 1px solid #00000026;
  border-radius: 4px;

  .ant-table-thead > tr > th {
    border-bottom: 1px solid #00000026;
  }

  .ant-table-tbody > tr > td {
    border-bottom: 1px solid #00000026;
    padding: 1rem 1.6rem;
  }

  tbody > tr {
    &:last-child {
      > td {
        border-bottom: none;
      }
    }
  }

  .ant-table-thead .ant-table-cell {
    padding: 1rem 1.6rem;
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 1.9rem;
    background-color: #fff;
  }

  .ant-table-thead,
  .ant-table-tbody {
    .ant-table-cell {
      color: #000;

      &::before {
        display: none;
      }
    }
  }

  .DraggableRow {
    width: 100%;
  }
}
