.AvatarUploader {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__AvatarBox {
    width: 12.8rem;
    height: 12.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1.6rem;
    border: 1px solid #00000026;
    border-radius: 4px;
  }

  &__Avatar {
    width: 8rem;
    height: 8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-size: 3rem;
    color: #fff;
    background-color: #ccc;
    text-transform: uppercase;
  }

  &__Upload {
    width: 100%;
  }

  &__Remove {
    margin-top: 1.2rem;
  }
}
