.ProjectPreview {
  display: flex;
  width: 80%;
  min-height: 52rem;

  &__Sidebar {
    display: flex;
    flex-direction: column;
    background-color: #000;
    flex: 0 0 25.6rem;
  }

  &__Content {
    background-color: #fff;
    width: 100%;
  }
}