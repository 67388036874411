.TimeDisplay {
  display: inline-block;
  width: fit-content;
  white-space: nowrap;

  &.TimeDisplay--Hovered {
    border: 1px solid #00000026;
    border-radius: 0.4rem;
    padding: 0.5rem;
  }
}
