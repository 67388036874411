.ShiftTimelineCard {
  width: 100%;
  padding: 1.6rem;
  background: #ffffff;
  border: 1px solid hsla(0, 0%, 0%, 0.149);
  border-radius: 4px;
  display: flex;

  &__Hours {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-right: 0.8rem;
    position: relative;

    &::after {
      position: absolute;
      content: "";
      width: 1px;
      top: 0;
      bottom: 0;
      right: -1.6rem;
      background-color: #00000026;
    }
  }

  &__Hour {
    font-size: 1.2rem;
    line-height: 1.7rem;
    height: 3.7rem;

    &:last-child {
      height: unset;
    }
  }

  &__Bars {
    width: 100%;
    margin-top: 0.8rem;
  }

  &__Bar {
    border-top: 1px solid #00000026;
    padding: 1.5rem;
    padding-right: 0;

    &:last-child {
      border-bottom: 1px solid #00000026;
    }

    > .TimeBar {
      margin-bottom: 0;
    }
  }

  &__Text {
    font-size: 1.2rem;
    line-height: 1.7rem;
    padding: 1rem 1.5rem;
    text-align: center;
  }
}
