.CheckoutTaskCollapsePanelHeader {
  align-items: center;
  display: flex;
  justify-content: space-between;

  &__Left {
    align-items: center;
    display: flex;
    justify-content: flex-start;

    > * {
      &:first-child {
        margin-right: 0.6rem;
      }
      &:not(:first-child):not(:last-child) {
        margin-right: 0.4rem;
      }
    }
    > .anticon {
      font-size: 2.4rem;
    }
    > span {
      @include openSansSemiBold;
      font-size: 1.4rem;
      line-height: 1.9rem;
    }
    &__Caret {
      cursor: pointer;
      transition: transform 0.3s;
      transform: rotate(-90deg);
      &--Open {
        transform: rotate(0deg);
      }
    }
  }

  &__Right {
    align-items: flex;
    display: flex;
    justify-content: center;

    > * {
      &:not(:last-child) {
        margin-right: 1.6rem;
      }
    }

    > .anticon {
      align-items: center;
      display: flex;
      justify-content: center;
      font-size: 1.4rem;
      margin-right: 0.6rem;
    }

    &__CheckoutTaskTime {
      @include openSansRegular;
      font-size: 1.4rem;
      line-height: 1.9rem;
    }
  }
}
