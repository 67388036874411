.WorkDayShifts {
  @include workTimeWidget;

  &__Title {
    > .anticon {
      font-size: 1.2rem;
    }
  }

  &__Content {
    display: table;
    table-layout: fixed;
    width: 100%;
  }

  &__Content__Data {
    border: 1px solid transparent;
    border-collapse: collapse;
    display: table-row;

    > * {
      display: table-cell;
      position: relative;
      text-align: center;
      vertical-align: middle;
      white-space: nowrap;

      .WorkDayShifts__Content__Data__Tag {
        @extend .vertical-center;
      }

      &:first-child,
      &:nth-last-child(2) {
        text-align: left;
      }

      &:last-child {
        color: $dark-gray;
        text-align: right;
      }
    }

    &:not(:last-child) {
      > * {
        border-bottom: solid 0.6rem transparent;
      }
    }
    &:first-child {
      > * {
        border-bottom: solid 1.2rem transparent;
      }
    }
  }
}
