.CheckoutHistory {
  background: $white;
  border: 1px solid $gray;
  border-radius: 0.4rem;

  > .ant-collapse-item {
    border-bottom: 0;

    > .ant-collapse-content {
      > .ant-collapse-content-box {
        padding: 0;
      }
    }
  }

  &__DateList {
    &__Item {
      align-items: center;
      border-bottom: 1px solid $gray;
      display: flex;
      justify-content: space-between;
      padding: 1.2rem 1.6rem;
      padding-left: 4.8rem;

      &:first-child {
        border-top: 1px solid $gray;
      }
    }
  }

  &__Summary {
    @include openSansSemiBold;
    align-items: center;
    display: flex;
    justify-content: flex-end;
    padding: 1.2rem 1.6rem;
    padding-left: 4.8rem;
  }
}
