.Priority {
  align-items: center;
  display: flex;
  height: fit-content;
  position: relative;
  width: fit-content;
}

.Priority__Component {
  cursor: pointer;
}

.Priority__Popover {
  .ant-popover-inner-content > div > div:last-child {
    margin-bottom: 0;
  }
  .ant-popover-inner-content {
    padding: 0;

    .Priority__Clicked {
      @include grayBorder();
      align-items: center;
      background: $white;
      border-radius: 0.4rem;
      box-shadow: 0 0 0.3rem $gray;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-left: -4.8rem;
      min-width: 9.6rem;
      padding: 1.2rem 0.8rem;
      position: absolute;
      z-index: 3;

      .Priority__Clicked__Button {
        align-items: center;
        border: none;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        width: 100%;

        @include openSansRegular();
        font-size: 1.2rem;
      }
    }
  }
}
