.Details {
  align-items: center;
  background-color: $white;
  display: flex;
  justify-content: center;
  min-width: 48rem;
  width: 35.13909vw;
  flex: 1;

  &__Message {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &__Icon {
      font-size: 8rem;
      margin-bottom: 2.4rem;
    }

    &__Title {
      @include openSansSemiBold;
      color: $black;
      font-size: 2rem;
      line-height: 2.7rem;
      margin-bottom: 1.6rem;
    }

    &__Text {
      @include openSansRegular;
      font-size: 1.4rem;
      line-height: 2.1rem;
      text-align: center;
    }
  }
}
