.MemberOption {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #00000026;
  padding: 0.8rem;

  &__Info {
    display: flex;
  }

  &__Info>span {
    margin-left: 0.9rem;
    font-size: 1.2rem;
    line-height: 1.7rem;
    color: #000;
  }

  &__FallbackText {
    font-size: 1rem !important;
  }

  &__btn {
    height: 2rem;
    padding: 0.1rem 0.8rem 0.2rem 0.9rem;
    border: 1px solid #00000026;
    border-radius: 4px;
    font-size: 1.2rem;
    line-height: 1.7rem;
    color: #000;
  }

  &:hover {
    background-color: #fafafa;
  }

  &:last-child {
    border-bottom: none;
  }
}