.TrackingTag {
  > .anticon {
    font-size: 2rem;
  }
}

.TrackingTag__Popover {
  padding-bottom: 0;

  .ant-popover-inner-content {
    padding: 0;
  }
}

.TrackingTag__Popover__Content {
  align-items: flex-start;
  box-shadow: 0 0 0.8rem #00000026;
  display: flex;
  flex-direction: row;
  height: 5.6rem;
  justify-content: flex-start;
  width: 43.6rem;

  > * {
    padding: 0.8rem 1.2rem;
  }

  &__Left {
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;

    &__Header {
      @include openSansRegular;
      align-items: center;
      color: $black;
      display: flex;
      font-size: 1.4rem;
      justify-content: space-between;
      line-height: 1.9rem;
      margin-bottom: 0.7rem;
      width: 100%;

      &__Title {
        max-width: 63.5321%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    &__Destination {
      @include openSansRegular;
      align-items: center;
      display: flex;
      font-size: 1rem;
      justify-content: flex-start;
      line-height: 1.4rem;
      width: 100%;

      > *:not(:last-child) {
        margin-right: 0.6rem;
      }
    }
  }

  &__Right {
    align-items: center;
    background-color: $off-white;
    display: flex;
    flex-basis: 7rem;
    height: 100%;
    justify-content: center;

    .CloseBtn {
      @include openSansRegular;
      background-color: $off-white;
      border: 1px solid $gray;
      font-size: 1.2rem;
      height: 2rem;
      line-height: 1.7rem;
      padding: 0.1rem 0.8rem 0.2rem 0.8rem !important;
    }
  }
}
