.TimeBar {
  width: 100%;
  height: 0.6rem;
  margin-bottom: 1.6rem;
  display: flex;

  &__Skeleton {
    li {
      height: 0.6rem !important;
      border-radius: 0 !important;
    }
  }
}