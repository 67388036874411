.ExternalIntegrationForm {
  @include memberForm;

  #externalTrackerConfig,
  #externalCalendarConfig {
    border-radius: 0.4rem;
    height: 6.4rem;
    max-height: 6.4rem;
    resize: none;
  }
}
