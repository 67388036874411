.TaskPanel {
  align-self: stretch;
  background: $off-white;
  border-right: 1px solid $gray;
  display: flex;
  flex-grow: 1;
  justify-content: flex-start;
  flex-direction: column;
  position: relative;
  width: 41.87408vw;

  &__Loading {
    align-items: center;
    align-self: center;
    display: flex;
    justify-content: center;
    flex-grow: 1;
  }
}
