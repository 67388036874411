.TaskContributorsDisplay {
  &__Title {
    @include openSansSemiBold;
    border-top: 1px solid $gray;
    border-bottom: 1px solid $gray;
    font-size: 1rem;
    line-height: 1.4rem;
    margin: 0 auto;
    padding: 0.5rem;
    text-align: center;

    &::first-letter {
      text-transform: capitalize;
    }
  }
  &__List {
    @include openSansRegular;
    align-items: center;
    display: flex;
    flex-direction: column;
    font-size: 1.4rem;
    height: fit-content;
    justify-content: flex-start;
    line-height: 1.9rem;
    max-height: 28rem;
    overflow-y: auto;

    &--Empty {
      justify-content: center;
      min-height: 6.9rem;
    }

    &__Item {
      align-items: center;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 0.8rem 1rem;
      width: 100%;

      &__UserDetails {
        @include openSansRegular;
        align-items: center;
        display: flex;
        flex-direction: row;
        font-size: 1.4rem;
        justify-content: flex-start;
        line-height: 1.9rem;

        .CustomAvatar {
          margin-right: 0.8rem;
        }
      }

      &__RemoveBtn {
        align-items: center;
        display: flex;
        height: 2rem;
        justify-content: center;
        padding: 0;
        width: 2rem;

        > .anticon {
          line-height: 0;
        }
      }

      &__AddBtn {
        @include openSansRegular;
        align-items: center;
        display: flex;
        font-size: 1.2rem;
        height: 2rem;
        justify-content: space-between;
        line-height: 1.7rem;
        padding: 0 0.8rem 0 0.4rem;

        > .anticon {
          color: $light-green;
          font-size: 2rem;
        }
      }
    }
  }
}
