.LabelEditor {
  &__Content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.6rem;
  }

  &__Input {
    margin-right: 1.6rem;
    border-radius: 4px;
  }

  &__Btns {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}