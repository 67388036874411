.GlobalSystemMessage {
  @include openSansRegular;
  align-items: center;
  display: flex;
  font-size: 1.2rem;
  height: 3.2rem;
  justify-content: center;
  line-height: 1.7rem;
  transition: all 0.5s ease-in-out;
  transform-origin: left top;
  transform: scaleY(1);
  width: 100%;

  &.GlobalSystemMessage--Error {
    background-color: $red;
    color: $white;
  }

  .GlobalSystemMessage__ErrorIcon {
    font-size: 2rem;
    margin-right: 0.8rem;
  }

  .GlobalSystemMessage__MoreInfo {
    margin-left: 0.8rem;
    cursor: pointer;
  }

  .GlobalSystemMessage__CloseIcon {
    cursor: pointer;
    position: absolute;
    right: 0.6rem;

    svg {
      height: 2rem;
      width: 2rem;
    }
  }
}
