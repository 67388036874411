.NotificationsPopoverContent {
  height: fit-content;
  width: 40rem;

  &__Header {
    align-items: center;
    border-bottom: 1px solid $gray;
    display: flex;
    height: 4.8rem;
    justify-content: space-between;
    padding: 1.2rem;

    &__Title {
      @include openSansSemiBold;
      font-size: 1.4rem;
      line-height: 1.9rem;
    }
  }

  &__Body {
    max-height: 43.2rem;
    overflow-y: scroll;
    
    &::-webkit-scrollbar:vertical {
      border-bottom-right-radius: 0.4rem;
    }
    &::-webkit-scrollbar-track {
      border-bottom-right-radius: 0.4rem;
    }
  }
}
