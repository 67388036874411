.CustomAvatar {
  align-items: center;
  display: flex;
  height: fit-content;
  justify-content: center;
  position: relative;
  width: fit-content;

  &__StatusCircle {
    border: 0.2rem solid;
    border-radius: 50%;
    padding: 0.2rem;

    &--working {
      border-color: $light-green;
    }

    &--taking_break {
      border-color: $blue;
    }

    &--out_of_office {
      border-color: $red;
    }
  }

  &__WorkStatus {
    border: 0.2rem solid #f2f9ff;
    border-radius: 50%;
    bottom: -0.1rem !important;
    box-sizing: content-box;
    height: 0.8rem !important;
    position: absolute;
    right: -0.1rem;
    width: 0.8rem !important;

    &--working {
      background-color: $light-green;
    }

    &--taking_break {
      background-color: $blue;
    }

    &--out_of_office {
      background-color: $red;
    }
  }

  &__FallbackText {
    align-items: center;
    display: flex;
    font-size: 100% !important;
    justify-content: center;
    text-transform: uppercase;

    .ant-avatar-string {
      height: fit-content;
      line-height: normal;
    }
  }
}
