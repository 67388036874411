.NotificationsList {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .NotificationsItem {
    &:not(:last-child) {
      border-bottom: 1px solid $gray;
    }
  }
}
