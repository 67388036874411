.NextShiftScheduleMoreActions {
  color: $dark-gray;
  cursor: pointer;

  &__Popover {
    padding-top: 0;

    .ant-popover-inner-content {
      padding: 0;
    }
  }
}
