.ManageTaskTime {
  align-items: center;
  border: 1px solid transparent;
  border-radius: 0.4rem;
  display: flex;
  height: 2.4rem;
  justify-content: center;
  padding: 0.2rem 0.6rem;

  &__Display {
    @include openSansRegular();
    color: $black;
    cursor: pointer;
    font-size: 1.2rem;
    position: relative;
    white-space: nowrap;
  }

  &--hovered {
    border: 1px solid $gray;
  }

  &--hasError {
    border: 1px solid $red;
  }
}

.ManageTaskTime__Popover {
  z-index: 1050;

  .ant-popover-inner-content > div > div:last-child {
    margin-bottom: 0;
  }
  .ant-popover-inner-content {
    padding: 0;
  }
}

.ManageTaskTime__ErrorPopover {
  padding-bottom: 0.6rem;

  .ant-popover-inner-content {
    @include openSansRegular;
    background-color: $light-red;
    border: 1px solid $red;
    border-radius: 0.4rem;
    box-shadow: none;
    color: $red;
    font-size: 1.2rem;
    line-height: 1.7rem;
    max-width: 19.2rem;
    padding: 0.8rem;
  }
}
