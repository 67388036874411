.RefreshBtn {
  .CustomButton {
    .anticon {
      svg {
        height: 2.4rem;
        width: 2.4rem;
      }
    }
  }
}
