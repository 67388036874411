.ActivityTabHeader {
  align-items: center;
  border-bottom: 1px solid $gray;
  display: flex;
  justify-content: space-between;
  padding: 1.2rem;

  &__Left,
  &__Right {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 0.6rem;
  }

  &__Subtab {
    @include openSansRegular;
    align-items: center;
    background-color: #0000000d;
    color: $dark-gray;
    display: flex;
    font-size: 1.2rem;
    height: 2.4rem;
    justify-content: space-between;
    line-height: 1.7rem;
    padding: 0.3rem 0.8rem;
    width: fit-content;

    &--Team {
      padding: 0.2rem 0.8rem 0.2rem 0.2rem;
    }

    .anticon {
      color: #0000003f;
      font-size: 1rem;
    }

    &__Count {
      margin-left: 0.8rem;
    }

    &:focus {
      background-color: #0000000d;
      border: 1px solid $gray;
      color: $dark-gray;
    }

    &--Active,
    &:hover {
      background-color: $blue !important;
      border: 1px solid transparent !important;
      color: $white !important;

      .anticon {
        color: $white;
      }
    }
  }
}
