.ListUnreadActivitiesCount {
  &__Loader {
    margin-top: -0.7rem;
    position: absolute;
    right: 1.7rem;
    top: 50%;
  }
  &__Badge {
    @include badgeCount;
  }
}
