.StartWorkChangeWallpaper {
  bottom: 4.7rem;
  color: $light-gray;
  cursor: pointer;
  font-size: $font-size-small;
  position: absolute;
  right: 5.2rem;
  transition: all ease 0.3s;

  &:hover {
      color: $white;
  }
}
