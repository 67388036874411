.FilterTasksButton {
  padding: 0.4rem;

  > .anticon {
    font-size: 2rem;
  }

  &__Popover {
    @include customPopover;
    padding-top: 0;
  }
}
