.TeamPopover {
  padding: 1.6rem;
  width: 30.4rem;
  border-radius: 0.4rem;
  color: #fff;
  background-color: #000;
  font-size: 1.2rem;
  line-height: 1.7rem;

  &__Header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 1.6rem;

    &__Info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    &__Name {
      font-size: 1.4rem;
      line-height: 1.9rem;
      font-weight: 600;
      color: #fcfcfc;
      margin-bottom: 0.6rem;
    }

    &__Icon {
      padding: 0.4rem;
      border: 1px solid #ffffff80;
      border-radius: 4px;
      font-size: 2.2rem;
      color: #ffffffcc;
      cursor: pointer;

      &--active {
        border: none;
        color: #fff;
        background-color: #0079f2;
      }
    }
  }

  &__Status {
    display: flex;
    align-items: center;
  }

  &__Dot {
    width: 0.8rem;
    height: 0.8rem;
    border-radius: 50%;
    margin-right: 0.8rem;

    &--working {
      background-color: #40bf00;
    }

    &--taking_break {
      background-color: #0079f2;
    }

    &--out-of-office {
      background-color: #ffaa00;
    }
  }

  &__Title {
    margin-top: 1.2rem;
    margin-bottom: 0.8rem;
    font-size: 1rem;
    line-height: 1.4rem;
    font-weight: 600;
    letter-spacing: 0.75px;
    color: #ffffff7f;
    text-transform: uppercase;
  }

  &__Description {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.4rem;
    color: #fcfcfc;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
