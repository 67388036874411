.SignOutModal {
  .Modal__PrefixText {
    margin-bottom: 2.4rem;
  }
  .Modal__SuffixText {
    margin-top: 3.6rem;
  }

  .ant-modal-content {
    padding: 4.8rem;
  }

  &__SignOut {
    color: #0079f2;
    cursor: pointer;
  }

  .LeaveTheOfficeBtn {
    height: 4rem;
  }

  .ant-modal-content .ant-modal-close {
    margin-right: -8rem;
  }
}
