.ManageSectionsModal {
  padding: 0;

  .ant-modal-body {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 2.4rem;
  }

  &__Table {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
    justify-content: flex-start;
    width: 100%;

    &__Header {
      align-items: center;
      display: flex;
      height: fit-content;
      justify-content: space-between;
      width: 100%;

      &__Title {
        @include openSansSemiBold;
        flex-basis: 25.9259%;
        font-size: 1.2rem;
        letter-spacing: 0.09rem;
        line-height: 1.7rem;
        text-transform: uppercase;
      }

      &__Action {
        transition: all 0.3s;

        &--Flex {
          flex-grow: 1;
        }

        &__Input {
          border-radius: 0.4rem;
          height: 2rem;
          padding: 0.2rem 0.8rem;

          &--HasInput {
            height: 3.7rem;
            padding: 0.9rem 0.8rem;
          }

          &__Suffix {
            align-items: center;
            display: flex;
            gap: 0.8rem;
            justify-content: flex-end;

            > .anticon {
              border: 1px solid $gray;
              border-radius: 0.4rem;
              color: $dark-gray;
              padding: 0.2rem;
              font-size: 1.4rem;
            }
          }
        }

        .AntButton {
          @include openSansRegular;
          align-items: center;
          display: flex;
          font-size: 1.2rem;
          gap: 0.4rem;
          height: 2rem;
          justify-content: flex-start;
          line-height: 1.7rem;
          padding: 0.2rem 0.8rem 0.2rem 0.4rem;

          > .anticon {
            color: $light-green;
            font-size: 2rem;
          }

          > span {
            margin-left: 0;
          }
        }
      }
    }

    &__Body {
      width: 100%;

      .DndTable {
        .ManageSectionsModal__TooltipHeader {
          align-items: center;
          display: flex;
          gap: 0.6rem;
          justify-content: flex-end;
        }
      }
    }
  }

  &__Menu {
    box-shadow: 0px 0px 3px $gray;

    > .ant-dropdown-menu-item {
      @include openSansRegular;
      font-size: 1.4rem;
      line-height: 1.9rem;

      > .anticon {
        color: $dark-gray;
        font-size: 2rem;
      }
    }
  }
}
