.AutogeneratedActivityStatus {
  &__ReportIcon {
    color: $dark-gray;
  }
  &__Text {
    @include openSansRegular;
    color: $black-99;
    font-size: 1rem;
    line-height: 1.4rem;
  }
}
