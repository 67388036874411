.WorkspaceMembersTable {
  &__Member {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 12.15226vw;

    .CustomAvatar {
      margin-right: 1.2rem;
    }

    &__Details {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      > span,
      > sub {
        width: 8.3455vw;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      > span {
        @include openSansSemiBold;
      }

      > sub {
        @include openSansRegular;
        font-size: 1.2rem;
        line-height: 1.7rem;
        height: fit-content;
      }
    }
  }

  &__FullName {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 6.4421vw;
  }

  &__UserType {
    text-transform: lowercase;
    &::first-letter {
      text-transform: capitalize;
    }
  }

  &__TeamPages {
    align-items: center;
    display: flex;
    justify-content: center;

    &__Tooltip {
      .ant-tooltip-inner {
        min-width: 12.7rem !important;
        max-width: 22.4rem !important;
        padding: 0.8rem !important;
        word-wrap: break-word !important;
        white-space: pre-wrap !important;
      }
    }

    > .ant-avatar {
      background-color: transparent;
      border: 1px solid $gray;
      > .anticon {
        color: $dark-gray;
        font-size: 1.4rem;
      }
    }

    > * {
      cursor: pointer;
    }
  }

  .ant-table {
    border-radius: 0.4rem;
    box-shadow: 0px 0px 3px $gray;
  }

  tr > th,
  tr > td {
    background-color: rgba(247, 247, 247, 0);
    text-align: left;
    white-space: nowrap;

    &:first-child {
      padding-left: 1.6rem;
      width: 16.7676%;
    }
    &:nth-child(2) {
      width: 8.8888%;
    }
    &:nth-child(3) {
      width: 6.5656%;
    }
    &:nth-child(4) {
      width: 14.0404%;
    }
    &:nth-child(5) {
      width: 7.3737%;
    }
    &:nth-child(5) {
      width: 7.3737%;
    }
    &:nth-child(6) {
      text-align: center;
      width: 10.404%;
    }
    &:nth-child(7) {
      padding-right: 1.6rem;
      width: 2.4242%;
    }
  }

  tr > th {
    @include openSansSemiBold;
    font-size: 1.4rem;
    line-height: 1.9rem;
    padding: 1rem 0 1.1rem 0;

    &:nth-child(6) {
      .anticon {
        margin-left: 0.6rem;
      }
    }
  }

  tr > td {
    @include openSansRegular;
    font-size: 1.4rem;
    line-height: 1.9rem;
    padding: 0.8rem 0;
  }

  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    content: none;
  }

  .ant-table-tbody > tr {
    cursor: pointer;
  }

  &__MoreActions {
    &__Popover {
      padding-top: 0;
      z-index: 1000;

      .ant-popover-inner {
        box-shadow: 0px 0px 3px $gray;

        .ant-popover-inner-content {
          padding: 0;
        }
      }

      &__Content {
        padding: 0.8rem 0;
      }
      &__Action {
        @include openSansRegular;
        align-items: center;
        cursor: pointer;
        display: flex;
        font-size: 1.2rem;
        justify-content: flex-start;
        line-height: 1.7rem;
        padding: 0.6rem 1.2rem 0.6rem 0.8rem;

        .anticon {
          color: $dark-gray;
          font-size: 2rem;
          margin-right: 0.8rem;
        }
      }
    }
  }
}
