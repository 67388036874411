.UnreadActivitiesHeader {
  background-color: #fff;
  color: #000;
  width: 100%;
  padding: 1.2rem;
  margin-top: -0.2rem;

  h1 {
    font-size: 1.6rem;
    line-height: 1.375;
    font-weight: 600;
    margin-bottom: 0;
  }

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.4rem;
    margin-right: 0;
    border: 1px solid #00000026;
    border-right: none;
    font-size: 2.2rem;
    color: #0000007f;
    background-color: #f7f7f7;
    cursor: pointer;

    &:first-child {
      border-top-left-radius: 0.4rem;
      border-bottom-left-radius: 0.4rem;
    }

    &:last-child {
      border-top-right-radius: 0.4rem;
      border-bottom-right-radius: 0.4rem;
      border-right: 1px solid #00000026;
    }

    &-active {
      background-color: #fff;
      color: #0079f2;
    }
  }

  &__filter {
    margin-left: 0.8rem;

    .UnreadActivitiesHeader__icon {
      background-color: #fff;
    }
  }

  &__cascader {
    width: 50rem;
  }
}
