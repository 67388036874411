.InTheOffice {
  align-items: flex-start;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  position: relative;

  > .ant-layout {
    width: 100%;

    > .ant-layout-header {
      height: fit-content;
    }

    > .ant-layout {
      flex-grow: 1;
    }
  }

  .InTheOffice__content {
    display: flex;
    overflow-x: visible !important;
  }

  &__RightSider {
    flex: 0 0 5.6rem !important;
  }
}
