.TimeSlot {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 0.6rem;
  }

  &__Title {
    flex: 1 0 6.4rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__Info {
    display: flex;
    align-items: center;
    min-width: 2rem;
  }

  &__Time {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 0.6rem;
  }

  &__Tip {
    font-size: 1.2rem;
    line-height: 1.7rem;
    color: #fff;
    padding: 0.2rem 0;
    width: 16rem;
  }

  &__TipItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.6rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__Icon-info {
    color: #0000007f !important;
  }

  &__Icon-note {
    color: #ffaa00 !important;
  }

  &__Icon-warning {
    color: #ff2900 !important;
  }
}
