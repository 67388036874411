.WorkspaceViewHeader {
  align-items: center;
  background-color: $white;
  box-shadow: 0px 0px 3px $gray;
  display: flex;
  height: 6.4rem;
  justify-content: space-between;
  padding: 1.2rem 1.6rem 1.2rem 2.4rem;

  &__Left {
    .BackToWorkspaceBtn {
      align-items: center;
      display: flex;
      justify-content: flex-start;
      padding: 0;
    }
  }

  .CustomAvatar {
  }
}
