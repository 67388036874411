.Label {
  width: 100%;
  display: flex;
  margin-bottom: 0.8rem;
  justify-content: space-between;
  align-items: center;

  &:last-child {
    margin-bottom: 0;
  }

  &__Body {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1 1 25.6rem;
    height: 2.4rem;
    padding: 0.3rem 0.45rem 0.4rem 0.8rem;
    margin-right: 0.8rem;
    border-radius: 4px;
    cursor: pointer;
  }

  &__Title {
    font-size: 1.2rem;
    line-height: 1.7rem;
  }

  &__Edit {
    border: 1px solid #00000026;
    border-radius: 4px;
    font-size: 2.2rem;
    color: #0000007f;
    cursor: pointer;
  }
}
