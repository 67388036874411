.CommentTooltip {
  &__Tooltip {
    .ant-tooltip-inner {
      @include openSansRegular;
      font-size: 1.2rem;
      height: fit-content !important;
      line-height: 1.8rem;
      overflow-wrap: break-word !important;
      white-space: pre-wrap !important;
      max-width: 18.74085vw;
      word-break: break-all !important;
    }
  }

  > .anticon {
    color: $blue;
  }
}
