.TimeCard {
  font-size: 1.4rem;
  line-height: 1.9rem;

  &-loading {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
}
