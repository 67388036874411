.CustomButton {
  @include defaultBtn;
  border-radius: 0.4rem;
  font-size: 1.2rem;
  height: 3.2rem;
  padding: 0.7rem 1.2rem 0.8rem 1.1rem;

  &:hover,
  &:focus,
  &:active {
    @include defaultBtn;

    &.ant-btn-icon-only {
      @include iconOnlyBtn;
    }
  }

  &.CustomButton--Success {
    @include successBtn;

    &:hover,
    &:focus,
    &:active {
      @include successBtn;
    }
  }

  &.ant-btn-sm {
    height: 2.4rem;
    padding: 0.3rem 0.8rem 0.4rem 0.8rem;

    &:hover,
    &:active {
      @include blueBtn;
    }
  }

  &.ant-btn[disabled],
  &.ant-btn[disabled]:hover,
  &.ant-btn[disabled]:focus,
  &.ant-btn[disabled]:active {
    @include disabledBtn;
  }

  .anticon {
    svg {
      color: #0000003f;
      height: 1.8rem;
      width: auto;
    }
  }

  &.ant-btn-icon-only {
    @include iconOnlyBtn;
  }

  > span {
    vertical-align: top;
  }

  &.ant-btn-default {
    @include customButton($white, $black);
  }

  &.ant-btn-primary {
    @include customButton($blue, $white);
  }

  &.ant-btn-success {
    @include customButton($light-green, $white);
  }

  &.ant-btn-danger {
    @include customButton($red, $white);
  }

  &.ant-btn-primary {
    background-color: $blue;
    border: 1px solid #f2f2f2;
    color: $white;

    &:hover,
    &:focus,
    &:active {
      border: 1px solid #f2f2f2 !important;
    }
  }

  &.ant-btn-danger {
    @include customButton($red, $white);
  }
}
