.ProjectPreview {
  align-items: center;
  display: flex;
  height: 67.8385%;
  flex-direction: row;
  justify-content: center;
  width: 90.6295%;

  &__Menu {
    align-items: flex-start;
    background-color: $black;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: flex-start;
    padding: 1.6rem 0;
    width: 41.357%;

    &__Title {
      @include openSansRegular;
      color: #ffffff7f;
      font-size: 1.2rem;
      letter-spacing: 0.09rem;
      line-height: 1.7rem;
      padding: 0 1rem 0.8rem 1rem;
      text-transform: uppercase;
    }

    &__Project,
    &__List {
      @include openSansRegular;
      background-color: #ffffff26;
      color: $white;
      font-size: 1.4rem;
      height: 4rem;
      line-height: 1.9rem;
      width: 100%;
    }

    &__Project {
      align-items: center;
      border-left: 3px solid $red;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      padding: 0.8rem 0.8rem 0.8rem 0.5rem;

      &__Initial {
        align-items: center;
        background-color: $red;
        border-radius: 0.2rem;
        display: flex;
        height: 2.4rem;
        justify-content: center;
        margin-right: 0.8rem;
        width: 2.4rem;
      }

      > .anticon {
        font-size: 2rem;
        margin-left: auto;
      }
    }

    &__List {
      opacity: 0.6;
      padding: 1rem 1rem 1.1rem 4rem;
    }

    &__ProjectPlaceholder,
    &__ListPlaceholder {
      align-items: center;
      display: flex;
      height: 4rem;
      justify-content: space-between;
      padding: 0.8rem;
      width: 100%;

      &__Details {
        align-items: center;
        display: flex;
        gap: 0.8rem;
        justify-content: flex-start;
        width: 64.0625%;
      }

      &__Initial,
      &__Title,
      &__Action {
        background-color: #ffffff66;
        border-radius: 0.2rem;
      }

      &__Initial {
        height: 2.4rem;
        width: 2.4rem;
      }

      &__Title {
        height: 1.2rem;
        flex-grow: 1;
      }

      &__Action {
        height: 1.8rem;
        width: 3.2rem;
      }
    }

    &__ListPlaceholder__Title {
      margin-left: 3.2rem;
    }
  }
  &__Content {
    align-items: flex-start;
    background-color: $white;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: flex-start;
    width: 58.6429%;

    &__Header {
      align-items: flex-start;
      border-bottom: 1px solid $gray;
      display: flex;
      flex-direction: column;
      height: fit-content;
      gap: 1.7rem;
      justify-content: flex-start;
      padding: 1.2rem 0 1.2rem 1.6rem;
      width: 100%;

      &__ProjectDetails {
        align-items: center;
        display: flex;
        gap: 1.2rem;
        justify-content: flex-start;

        &__Initial {
          align-items: center;
          background-color: $red;
          border-radius: 0.2rem;
          color: $white;
          display: flex;
          height: 3.2rem;
          justify-content: center;
          width: 3.2rem;
        }

        &__Title {
          @include openSansSemiBold;
          font-size: 1.6rem;
          line-height: 2.2rem;
        }
      }

      &__ListDetails {
        align-items: center;
        display: flex;
        gap: 1.2rem;
        justify-content: flex-start;

        &__Corner {
          align-items: flex-start;
          display: flex;
          height: 3.2rem;
          justify-content: flex-end;
          width: 3.2rem;

          > div {
            border-left: 1px solid $black;
            border-bottom: 1px solid $black;
            height: 2rem;
            width: 1.6rem;
          }
        }

        &__Title {
          @include openSansSemiBold;
          font-size: 1.6rem;
          line-height: 2.2rem;
        }
      }

      &__SearchBar {
        border-top-left-radius: 0.4rem;
        border-bottom-left-radius: 0.4rem;
        border: 1px solid $gray;
        border-right: 0;
        height: 3.2rem;
        width: 100%;
      }
    }
    &__Body {
      flex-grow: 1;
      padding: 1.6rem 0 1.6rem 1.7rem;
      width: 100%;

      &__TaskPlaceholder {
        border-left: 1px solid $gray;
        border-top: 1px solid $gray;
        display: flex;
        flex-direction: column;
        gap: 1.6rem;
        height: 6.8rem;
        padding: 1.6rem;

        &:first-child {
          border-top-left-radius: 0.4rem;
        }

        &:last-child {
          border-bottom: 1px solid $gray;
          border-bottom-left-radius: 0.4rem;
        }

        &__Title {
          background-color: $gray;
          border-radius: 0.2rem;
          height: 1.2rem;
          width: calc(100% - 7.5rem);
        }

        &__Tags {
          align-items: center;
          display: flex;
          gap: 1.2rem;
          justify-content: flex-start;

          > div {
            background-color: $gray;
            border-radius: 0.2rem;
            height: 0.8rem;
            width: 4.8rem;
          }
        }
      }
    }
  }
}
