.Workspace {
  position: absolute;
  left: 0.5rem;
  top: 0.5rem;
  width: 39.9rem;
  height: 37.6rem;
  color: #000;
  background: #FFF;
  box-shadow: 0px 0px 3px #00000026;
  border-radius: 4px;
  opacity: 0;
  z-index: -1;
  display: flex;

  font-size: 1.4rem;
  line-height: 1.9rem;

  &.open {
    opacity: 1;
    z-index: 1200;
  }

  &__Content {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__Header {
    display: flex;
    justify-content: flex-start;
    padding: 0.6rem;
    cursor: pointer;

    &__Avatar {
      margin-right: 1rem;
    }

    &__Text {
      @include flexCenter();
      font-weight: 600;
    }
  }

  &__Body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: 0.5rem 2.4rem 2.4rem 5.5rem;
  }

  &__List {
    &-item {
      padding: 1rem 0;
      letter-spacing: 0px;
      border-bottom: 1px solid #00000026;
      cursor: pointer;
    }

    &-item.disable {
      cursor: not-allowed;
    }
  }

  &__Sidebar {
    flex: 0 0 6.4rem;
    background: #F2F2F2;
  }

  .ant-btn {
    height: 4rem;
    padding: 1rem 4rem;
    font-size: 1.4rem;
    line-height: 1.9rem;
  }
}