.ProjectCollapsePanelHeader {
  align-items: center;
  display: flex;
  justify-content: space-between;

  &__Left {
    align-items: center;
    display: flex;

    .ProjectInitial {
      margin-right: 0.8rem;
    }

    &__Title {
      margin-right: 0.4rem;

      &__Text {
        @include openSansSemiBold;
        color: $black;
        font-size: 1.4rem;
        line-height: 1.9rem;
      }
    }

    &__Icon {
      cursor: pointer;
      transition: transform 0.3s;
      transform: rotate(-90deg);

      &--Open {
        transform: rotate(0deg);
      }
    }
  }

  &__Right {
    align-items: center;
    display: flex;
    justify-content: flex-end;

    > *:not(:last-child) {
      margin-right: 1.6rem;
    }
  }
}
