.TaskDestinationDisplay {
  @include openSansRegular;
  align-items: center;
  display: flex;
  justify-content: flex-start;
  font-size: 1rem;
  line-height: 1.4rem;

  > *:not(:last-child) {
    margin-right: 0.6rem;
  }
}
