.TaskPanelHeader {
  align-items: center;
  background: $white;
  border-bottom: 1px solid $gray;
  display: flex;
  justify-content: space-between;
  position: relative;

  &__Container {
    align-items: left;
    display: flex;
    flex-direction: column;
    justify-content: left;
    padding: 1.2rem 1.6rem;
    width: 100%;

    &__Wrapper {
      align-items: center;
      display: flex;
      height: 3.2rem;
      justify-content: space-between;
      width: 100%;

      &:not(:last-child) {
        margin-bottom: 1.2rem;
      }

      &__Title {
        @include openSansSemiBold;
        align-items: center;
        display: flex;
        font-size: 1.6rem;
        justify-content: flex-start;
        line-height: 2.2rem;
        margin-bottom: 0 !important;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        &__Text {
          overflow: hidden;
          text-overflow: ellipsis;
          text-transform: capitalize;
          white-space: nowrap;
        }
      }

      &__ActionsContainer {
        align-items: center;
        color: $dark-gray;
        display: flex;
        height: 3.2rem;
        justify-content: right;
        margin-bottom: 0;

        .ManageTaskTime {
          height: 3.2rem !important;
          width: 7.3rem !important;
        }

        .ManageTaskTime__Display {
          align-items: center;
          display: flex;
          height: 100% !important;
          justify-content: flex-end;
          padding: 0.6rem 0.8rem 0.7rem 0.8rem !important;
          width: 100% !important;
        }

        &__Action {
          align-items: center;
          border: 1px solid $gray;
          border-radius: 0.4rem;
          cursor: pointer;
          display: flex;
          font-size: $font-size-medium;
          justify-content: center;
          height: inherit;
          text-align: center;
          width: 3.2rem;

          > .anticon {
            font-size: 2.4rem;
          }

          &:not(:last-child) {
            margin-right: 0.8rem;
          }

          &__Popover {
            border-radius: 0.4rem;
            padding-top: 0;

            .ant-popover-content {
              border-radius: 0.4rem;

              .ant-popover-inner {
                border-radius: 0.4rem;
                box-shadow: 0px 0px 3px #00000026;

                .ant-popover-inner-content {
                  border-radius: 0.4rem;
                  padding: 0 !important;
                }
              }
            }

            .PanelHeader__ActionsContainer__Action__Popover__Content {
              align-items: center;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              padding: 0.8rem 0;

              &--Task {
                height: 8rem;
                padding: 0.8rem 0;
                width: 15rem;

                > div {
                  padding: 0.4rem 1.2rem;
                }
              }

              &--ProjectAction {
                height: 11.2rem;
                width: 7.2rem;

                > div {
                  padding: 0.6rem 1.2rem 0.7rem 1.2rem;
                }
              }

              &--ListAction {
                height: fit-content;
                width: 14.3rem;

                > * {
                  padding: 0.6rem 1.2rem 0.7rem 1.2rem;
                  width: 100%;
                }
              }

              > div {
                align-items: center;
                display: flex;
                justify-content: space-between;
                width: 100%;

                > span {
                  @include openSansRegular;
                  font-size: 1.2rem;
                  line-height: 1.7rem;
                }

                > .anticon {
                  color: $dark-gray;
                  font-size: 2.4rem;
                }
              }
            }
          }
        }
      }
    }
  }
}
