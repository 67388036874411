.ContributorList {
  width: 100%;

  &__Title {
    padding: 0.5rem;
    border-top: 1px solid #00000026;
    border-bottom: 1px solid #00000026;
    font-size: 1rem;
    line-height: 1.4rem;
    font-weight: 600;
    letter-spacing: 0px;
    color: #000;
    text-align: center;
  }

  &__Empty {
    margin: 1.6rem 0;
  }
}