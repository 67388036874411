.MergeTaskButton {
  @include openSansRegular;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 0.8rem;
  font-size: 1.2rem;
  line-height: 1.7rem;
  cursor: pointer;

  &:hover {
    background-color: #f5f5f5;
  }

  .anticon {
    color: #0000007f;
    font-size: 2rem;
    margin-right: 0.8rem;
  }
}
