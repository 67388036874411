.CheckoutItem {
  display: flex;
  flex-direction: column;
  color: #000;
  width: 100%;

  &__Header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.2rem;

    &__Main {
      display: flex;
      justify-content: flex-start;
    }
  }

  &__Header &__Title {
    font-size: 1.2rem;
    line-height: 1.7rem;
    font-weight: 600;
    letter-spacing: 0.9px;
    text-transform: uppercase;
    margin-right: 0.6rem;
  }

  &__Content {
    background-color: $white;
    border: 1px solid #00000026;
    border-radius: 4px;
    padding: 1.6rem 1.2rem;
    position: relative;
    height: 100%;

    > .anticon-loading {
      margin: auto;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      width: fit-content;
      height: fit-content;
    }
  }
}
