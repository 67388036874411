.CheckoutTaskCollapsePanelFooter {
  align-items: center;
  border: 1px solid $gray;
  border-top: 0;
  border-bottom-left-radius: 0.4rem;
  border-bottom-right-radius: 0.4rem;
  display: flex;
  justify-content: flex-end;
  padding: 1.6rem;

  &__TotalTime {
    @include openSansSemiBold;
    font-size: 1.4rem;
    line-height: 1.9rem;

    > span {
      &:first-child {
        margin-right: 0.8rem;
      }
    }
  }
}
