.CheckoutStatusMessages {
  &__Header {
    display: flex;
    justify-content: space-between;
  }

  &__Title {
    font-size: 2rem;
    line-height: 2.7rem;
    font-weight: 600;
  }

  &__Extra {
    display: flex;
    align-items: center;
  }

  &__Select {
    width: 19rem;
    margin-right: 1.1rem;
    border-radius: 4px;

    .ant-select-selector {
      height: 2.4rem !important;
      border-radius: 4px !important;
    }

    .ant-select-selection-item {
      line-height: 1.9rem !important;
    }
  }

  &__Button {
    padding: 0 0.8rem;
    line-height: 1.357;
    border-radius: 4px;
  }

  &__Messages {
    margin-top: 2.4rem;
    max-height: 75vh;
    overflow-y: auto;
  }
}
