.CheckoutTimeSummary {
  border: 1px solid $gray;
  border-radius: 0.4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1.6rem;

  &__TotalTimeContainer {
    align-items: center;
    border: 1px solid $gray;
    display: flex;
    height: 1.2rem;
    justify-content: center;
    margin-bottom: 1.6rem;

    &__Projects,
    .CheckoutTaskTimeBar,
    .CheckoutUnallocatedTimeBar,
    .CheckoutProjectTimeBar {
      height: 100%;
    }

    &__Projects {
      align-items: flex-start;
      display: flex;
      justify-content: flex-start;
    }
  }

  &__Time {
    @include openSansSemiBold;
    align-items: flex-end;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    font-size: 1.4rem;
    line-height: 1.9rem;

    > * {
      &:not(:last-child) {
        margin-bottom: 0.8rem;
      }
    }

    &__Unallocated {
      align-items: center;
      display: flex;
      justify-content: flex-end;

      > .anticon {
        color: $warning;
        font-size: 1.6rem;
        margin-right: 0.4rem;
      }
    }
  }
}
