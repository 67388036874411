.CardEmptyState {
  align-items: center;
  background: $white;
  border: 1px solid $gray;
  border-radius: 0.4rem;
  display: flex;
  justify-content: center;
  padding-top: 2.7rem;
  padding-bottom: 2.6rem;

  > span {
    @include openSansRegular;
    font-size: $font-size-small;
    line-height: 1.9rem;
  }

  &--projectPanel,
  &--listPanel {
    height: 7.2rem;
  }
  &--taskPanel {
    height: 9.3rem;
  }
}
