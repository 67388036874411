.RadioGroup {
  display: flex;

  &__Option {
    width: 9.6rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.1rem 0.8rem;
    background-color: #F7F7F7;
    border: 1px solid #5c3b3b26;
    border-left: none;
    font-size: 1.2rem;
    line-height: 1.417;
    color: #0000007F;
    cursor: pointer;

    &:first-child {
      border-top-left-radius: 0.4rem;
      border-bottom-left-radius: 0.4rem;
      border-left: 1px solid #5c3b3b26;
    }

    &:last-child {
      border-top-right-radius: 0.4rem;
      border-bottom-right-radius: 0.4rem;
    }
  }

  &__Option.RadioGroup__Option-active {
    background-color: #fff;
    color: #0079F2;
  }

  &__Option.RadioGroup__Option-center {
    justify-content: center;
  }
}