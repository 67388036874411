.ProjectForm {
  @include memberForm;
  flex-basis: 51.2rem;

  #projectForm {
    border: 0;
  }

  &__Title {
    font-size: 2rem;
    font-weight: 600;
    line-height: 1.35;
    color: #000000;
    margin-bottom: 2.4rem;
  }

  &__Item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #f0f0f0;
    padding: 1.2rem 0;

    > .ant-form-item {
      margin-bottom: 0;
    }

    .ant-form-item-explain {
      width: 32rem;
    }

    input {
      width: 32rem;
    }
  }

  &__Item-name {
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 1.36;
    margin-bottom: 0;
  }

  &__Item-name.required::after {
    content: "*";
    color: red;
    margin-left: 0.4rem;
  }

  &__Item-value {
    width: 32rem;
    display: flex;
    justify-content: flex-start;
  }

  &__Button {
    width: 100%;
    margin-top: 3.3rem;
  }

  .ant-form-item {
    &.ProjectForm__TaskListName {
      border-bottom: 0 !important;
      padding-bottom: 0;
    }

    > .ant-form-item-label {
      flex-basis: 37.5%;
    }

    > .ant-form-item-control {
      flex-basis: 62.5%;

      .ant-form-item-control-input-content {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        gap: 1.2rem;
        justify-content: flex-start;

        textarea.ant-input {
          height: 12rem;
          resize: none;
        }
      }
    }

    .ant-select-arrow,
    .ant-select-clear {
      height: 2.4rem;
      margin-top: 0;
      width: 2.4rem;
      right: 0.4rem;
      top: 50%;
      transform: translateY(-50%);

      .anticon {
        font-size: 2.4rem;
      }
    }
  }

  .ant-select-arrow > .anticon {
    color: $black;
  }
}
