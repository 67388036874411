.ShiftPopover {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__Btn {
    margin-top: 1.2rem;
    width: 100%;
    text-transform: capitalize;
  }
}