.ActivityInput {
  border-bottom: 1px solid $gray;
  padding: 0.7rem 0 1.3rem 0;
  position: relative;
  width: 35.1rem;

  &:not(:last-child) {
    margin-top: 1rem;
  }

  &:not(:first-child) {
    margin-top: 0.4rem;
  }

  .ActivityInput__Label {
    @include openSansRegular;
    color: $dark-gray;
    font-size: 1.2rem;
    height: 1.7rem;
    width: 100%;
  }

  .ActivityInput__TextArea {
    @include openSansRegular;
    font-size: 1.4rem;
    height: 3.2rem;
    left: 0;
    max-height: 3.2rem;
    padding: 0.5rem 0 0.8rem 0;
    position: absolute;
    resize: none;
    top: 0;
  }

  &.ActivityInput--Filled,
  &.ActivityInput--Focused,
  &.ActivityInput--NoLabel {
    padding-bottom: 0;

    .ActivityInput__TextArea {
      position: relative;
    }
  }
}
