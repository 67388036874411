.UserTaskActivityList {
  width: 100%;
  
  .ActivityCardItem {
    &__Card {
      margin-left: 0;
      width: 100% !important;

      .ActivityCardItemBody {
        width: 100%;

        .ActivityInputDisplay,
        .ActivityInput {
          width: 100%;
        }
      }
    }

    &:not(:last-child) {
      margin-bottom: 2rem;
    }
  }
}
