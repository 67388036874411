.TextFormattingToolbar {
  .element {
    &--link {
      .popup {
        position: absolute;
        left: 0;
        display: flex;
        align-items: center;
        background-color: white;
        padding: 6px 10px;
        gap: 10px;
        border-radius: 6px;
        border: 1px solid lightgray;
      }
    }
    &--blockquote {
      border-left: 2px solid #ddd;
      margin-left: 0;
      margin-right: 0;
      padding-left: 10px;
      color: #aaa;
      font-style: italic;
    }
  }
  .slate-leaf {
    &--bold {
      font-weight: bold;
    }
    &--italic {
      font-style: italic;
    }
    &--underline {
      text-decoration: underline;
    }
    &--strikethrough {
      text-decoration: line-through;
    }
  }
}
