.NextShiftScheduleMoreActionsPopoverContent {
  border-radius: 0.4rem;
  box-shadow: 0px 0px 3px $gray;
  padding: 0.8rem;

  &__Action {
    &__Text {
      @include openSansRegular;
      cursor: not-allowed;
      font-size: 1.2rem;
      line-height: 1.7rem;
      padding-top: 0.3rem;
      padding-bottom: 0.4rem;
      white-space: nowrap;
    }
  }
}
