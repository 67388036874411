.CustomDateRangePicker {
  align-items: center;
  display: flex;
  justify-content: flex-end;

  > *:not(:last-child) {
    margin-right: 0.8rem;
  }

  &__LeftCaret,
  &__RightCaret {
    > .anticon {
      align-items: center;
      display: flex;
      justify-content: center;
      font-size: 2rem;
    }
  }

  &__RangePicker {
    align-items: center;
    border: 1px solid $gray;
    border-radius: 0.4rem;
    display: flex;
    height: 3.2rem;
    justify-content: space-between;
    width: 19.8rem;

    &--SameDate {
      > .ant-picker-range-separator,
      > .ant-picker-input {
      }
    }
  }
  &__Presets {
    align-items: center;
    display: flex;

    &__Button {
      @include openSansRegular;
      background-color: $off-white;
      border: 1px solid $gray;
      color: $dark-gray;
      cursor: pointer;
      font-size: 1.4rem;
      height: 3.2rem;
      line-height: 1.9rem;
      padding: 0.6rem 1.2rem 0.7rem 1.2rem;
      text-transform: capitalize;
      white-space: nowrap;

      &--Active {
        background-color: $white;
        color: $blue;
      }

      &--Disabled {
        cursor: not-allowed;
      }

      &:first-child {
        border-top-left-radius: 0.4rem;
        border-bottom-left-radius: 0.4rem;
      }

      &:last-child {
        border-top-right-radius: 0.4rem;
        border-bottom-right-radius: 0.4rem;
      }

      &:not(:first-child) {
        border-left: 0;
      }
    }
  }

  &__Panel {
    padding: 1.6rem;

    &__Presets {
      align-items: center;
      display: flex;
      justify-content: space-between;
      padding-bottom: 1.6rem;

      &__Button {
        @include openSansRegular;
        align-items: center;
        border: 1px solid $gray;
        border-radius: 0.4rem;
        cursor: pointer;
        display: flex;
        flex-grow: 1;
        font-size: 1.2rem;
        height: 2.4rem;
        justify-content: center;
        text-align: center;
        text-transform: capitalize;

        &--Active {
          background-color: $blue;
          color: $white;
        }

        &:not(:last-child) {
          margin-right: 1.6rem;
        }
      }
    }
  }

  /*
  * AntDesign RangePicker Override
  */
  .ant-picker-panel {
    border-bottom: 0 !important;
  }
  .ant-picker-date-range-wrapper {
    border-radius: 0.4rem !important;
    box-shadow: 0px 0px 3px $gray !important;

    > .ant-picker-panel-container {
      border-radius: 0.4rem !important;
      box-shadow: none !important;
    }
  }

  .ant-picker-header {
    border-bottom: 0;
    margin-bottom: 1.6rem;
    padding: 0;

    > .ant-picker-header-prev-btn,
    > .ant-picker-header-next-btn {
      color: $dark-gray;
    }

    > .ant-picker-header-super-prev-btn,
    > .ant-picker-header-super-next-btn {
      display: none;
    }

    > .ant-picker-header-view {
      @include openSansSemiBold;
      font-size: 1.2rem;
      line-height: 1.7rem;
    }
  }

  .ant-picker-body {
    padding: 0;

    .ant-picker-content > thead,
    .ant-picker-cell {
      @include openSansRegular;
      font-size: 1.2rem;
      line-height: 1.7rem;
    }

    .ant-picker-cell {
      height: fit-content;
      width: fit-content;
      padding: 0;
      .ant-picker-cell-inner {
        display: none;
      }
    }
    .ant-picker-cell-in-view {
      .ant-picker-cell-inner {
        display: inline-block;
      }
    }

    .ant-picker-cell-in-view.ant-picker-cell-in-range::before {
      background: $light-blue;
    }

    .ant-picker-cell-range-start > .ant-picker-cell-inner {
      border-top-left-radius: 0.4rem !important;
      border-bottom-left-radius: 0.4rem !important;
    }
    .ant-picker-cell-range-end > .ant-picker-cell-inner {
      border-top-right-radius: 0.4rem !important;
      border-bottom-right-radius: 0.4rem !important;
    }
  }

  > .ant-picker-input {
    width: fit-content !important;

    > input {
      @include openSansRegular;
      font-size: 1.4rem;
      line-height: 1.9rem;
    }
  }
}
