.ActivityCardItemHeader {
  align-items: center;
  display: flex;
  min-height: 3.4rem;
  justify-content: space-between;

  .ActivityCardItemHeader__Details {
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: fit-content;

    .ActivityCardItemHeader__Details__Name {
      @include openSansSemiBold;
      font-size: 1.4rem;
      margin-right: 0.8rem;
    }
    .ActivityCardItemHeader__Details__UnreadTag {
      @include openSansRegular;
      background-color: $red;
      border-radius: 0.4rem;
      color: $white;
      font-size: 1.2rem;
      padding: 0 0.8rem 0.1rem 0.8rem;
    }
    .ActivityCardItemHeader__TeamIcon {
      color: #0000003f;
      margin-left: 0.8rem;

      svg {
        height: 2rem;
        width: 2rem;
      }
    }
  }

  .ActivityCardItemHeader__Actions,
  .ActivityCardItemHeader__DeletedDateTimeAgo {
    @include openSansRegular;
    align-items: right;
    display: flex;
    justify-content: center;
    font-size: 1.2rem;

    > div:not(:last-child) {
      margin-right: 0.6rem;
    }
  }
}
