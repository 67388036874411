.ItemUsersTaskTimeInWorkDayAndTask {
  align-items: center;
  border: 1px solid $gray;
  border-radius: 1.6rem;
  display: flex;
  height: 2.4rem;
  justify-content: flex-start;
  padding: 0rem 1.2rem;
  width: fit-content;

  &--Team {
    padding-left: 0.3rem;
    padding-right: 1.2rem;
  }

  .CustomAvatar {
    margin-right: 0.6rem;
  }

  &__Name {
    margin-right: 1.2rem;
  }

  &__Name,
  &__TaskTime {
    @include openSansRegular;
    font-size: 1.2rem;
    line-height: 1.7rem;
  }
}
