.SectionListDropdown {
  @include openSansRegular();
  align-items: center;
  border: 1px solid $gray;
  border-radius: 0.4rem;
  color: $dark-gray;
  cursor: pointer;
  display: flex;
  font-size: 1.2rem;
  height: 2.4rem;
  justify-content: space-between;
  padding: 0.4rem 0.8rem;
  width: 12.8rem;

  .SectionListDropdown__SelectedTitle {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.SectionListDropdown__Dropdown {
  background: $off-white;
  border: 1px solid $gray;
  border-radius: 0.4rem;
  max-height: 15rem;
  overflow: auto;
  width: 12.8rem;

  .ant-dropdown-menu-item {
    @include openSansRegular();
    color: $dark-gray;
    font-size: 1.2rem;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
