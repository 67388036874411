.WorkDayCheckoutStatus {
  align-items: center;
  display: flex;
  justify-content: center;

  &__Critical {
    color: $red;
  }

  &__NonCritical {
    color: $warning;
  }

  &__StillInOffice {
    color: $dark-gray;
  }

  > .anticon {
    font-size: 1.6rem;
  }

  .ant-popover-inner-content {
    padding: 0;
  }
}
