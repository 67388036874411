.CheckoutHeader {
  align-items: center;
  background-color: $white;
  border-bottom: 1px solid $gray;
  display: flex;
  height: 5.6rem;
  justify-content: space-between;
  padding: 1.2rem 1.6rem;
  width: 100%;

  &__Title {
    @include openSansSemiBold;
    font-size: 1.6rem;
    line-height: 2.2rem;
    margin-right: 29.43969610636277%;

    &__UserDetails {
      align-items: center;
      display: flex;
      justify-content: flex-start;

      .CustomAvatar {
        margin-right: 1.3rem;
      }

      &__Info {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        justify-content: center;

        &__Name {
          @include openSansSemiBold;
          font-size: 1.6rem;
          line-height: 2.2rem;
          margin-bottom: 0.2rem;
        }

        &__Role {
          @include openSansRegular;
          font-size: 1.2rem;
          line-height: 1.7rem;
        }
      }
    }
  }
}
