.CheckoutDateNavigation {
  align-self: center;
  display: flex;
  height: 3.2rem;
  justify-content: space-between;

  &__Arrow {
    height: 3.2rem;
    width: 3.2rem;

    svg: {
      height: 2rem;
      width: 2rem;
    }
  }

  &__Arrow--Prev {
    margin-right: 0.8rem;
  }

  &__Arrow--Next {
    margin-left: 0.8rem;
  }

  &__Today {
    min-width: 6.4rem;
    height: 3.2rem;
    padding: 0.6rem 1.2rem;
    border: 1px solid #00000026;
    border-radius: 4px;
    font-size: 1.4rem;
    line-height: 1.9rem;
    margin-right: 1.6rem;
    cursor: pointer;
  }

  &__DatePicker {
    .ant-picker-input > input {
      text-align: center;
    }
  }

  &__DateDropdown {
    .ant-picker-header-super-prev-btn,
    .ant-picker-header-super-next-btn {
      display: none;
    }
  }
}
