.ActivityContent {
  width: 100%;
  margin-top: 1.2rem;
  padding: 1.6rem;
  color: #000;
  background: #FFFFFF;
  border: 1px solid #00000026;
  border-radius: 4px;

  &__section {
    margin-bottom: 1.2rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__header {
    margin-bottom: 1.7rem;

    h1 {
      margin-bottom: 0;
    }
  }

  h1 {
    position: relative;
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 1.5;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: -1.7rem;
      width: 0.3rem;
      height: 2rem;
      background: #40BF00;
    }
  }

  h2 {
    font-size: 1.2rem;
    line-height: 1.75;
    color: #0000007F;
    margin-bottom: 0.8rem;
  }

  p {
    font-size: 1.4rem;
    line-height: 1.55;
    color: #000000;
    margin-bottom: 0;
  }

  &__note {
    font-size: 1.8rem;
    color: rgba(0, 0, 0, 0.15);
    cursor: pointer;
    color: #0000000D;
  }
}